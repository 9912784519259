<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="dialog-header-footer">
  <button mat-icon-button
          [attr.aria-label]="'translations.global.ariaLabels.closeDialog'"
          (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content class="dialog-content">
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort class="custom-table">

      <ng-container matColumnDef="header">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'translations.global.label.course' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.header }}</td>
      </ng-container>

      <ng-container matColumnDef="totalCards">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'translations.analytics.metric.cards' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.totalCards }}</td>
      </ng-container>

      <ng-container matColumnDef="totalMembers">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'translations.members.title' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.totalMembers }}</td>
      </ng-container>

      <ng-container matColumnDef="startedCourseCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'translations.analytics.metric.startedCourses' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.startedCourseCount }}</td>
      </ng-container>

      <ng-container matColumnDef="completedCourseCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'translations.analytics.metric.completedCourses' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.completedCourseCount }}</td>
      </ng-container>

      <ng-container matColumnDef="totalEventCardRegistrations">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'translations.analytics.metric.eventCardRegistrations' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.totalEventCardRegistrations }}</td>
      </ng-container>

      <ng-container matColumnDef="totalEnrollmentsCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'translations.analytics.metric.totalEnrollments' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.totalEnrollmentsCount }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</mat-dialog-content>

<div class="dialog-header-footer">
  <button class="export-button"
          mat-stroked-button
          [attr.aria-label]="('translations.global.button.downloadCsv' | transloco)"
          (click)="downloadCsvFile()">
    {{ 'translations.global.button.downloadCsv' | transloco }}
  </button>
</div>
