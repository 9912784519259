<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ptl-media-content-view
  [position]="mediaUploadData?.position || 'CENTER'"
  [caption]="mediaUploadData?.caption"
  [url]="mediaUploadData?.url"
  [canRemoveItem]="canRemoveItem"
  [openUrlInTab]="mediaUploadData?.openUrlInTab"
  [isUploadedDocument]="mediaUploadData?.type === 'MEDIA_UPLOAD' && mediaUploadData?.mediaType === 'DOCUMENT'"
  [isUploadedImage]="mediaUploadData?.type === 'MEDIA_UPLOAD' && mediaUploadData?.mediaType === 'IMAGE'"
  [isUploadedVideo]="mediaUploadData?.type === 'MEDIA_UPLOAD' && (mediaUploadData?.mediaType === 'VIDEO' || mediaUploadData?.mediaType === 'STREAM_VIDEO')"
  [uploadedVideoType]="(mediaUploadData?.mediaType === 'VIDEO' || mediaUploadData?.mediaType === 'STREAM_VIDEO') ? mediaUploadData?.mediaType : undefined"
  [isUploadedAudio]="mediaUploadData?.type === 'MEDIA_UPLOAD' && mediaUploadData?.mediaType === 'AUDIO'"
  [newEditor]="newEditor"
  (removeMediaContent)="removeMedia()"
  (updateMediaPosition)="onUpdateMediaPosition($event)"
  (mediaContentReplaced)="onReplaceMediaContent($event)"
  (regularLinkReplaced)="onReplaceRegularLink($event)"
  (updateMediaCaption)="onUpdateMediaCaption($event)"
  (updateMediaHyperlink)="onUpdateMediaHyperlink($event)">

  <ng-container *ngIf="mediaUploadData?.mediaType === 'IMAGE'">
    <img
      class="media-upload__browser-image"
      *ngIf="!getIsProgressBarVisible"
      alt="Uploaded image"
      [src]="mediaUploadSrc" />
    <ptl-progress-bar-upload
      *ngIf="getIsProgressBarVisible"
      [content]="mediaUploadData"
      (contentUploadProgressEmitter)="contentUploadProgress($event)">
    </ptl-progress-bar-upload>
  </ng-container>

  <ng-container *ngIf="mediaUploadData?.mediaType === 'VIDEO'">
    <video controls
           *ngIf="!getIsProgressBarVisible"
           class="media-upload__browser-video"
           [src]="mediaUploadSrc">
    </video>
    <ptl-progress-bar-upload
      *ngIf="getIsProgressBarVisible"
      [content]="mediaUploadData">
    </ptl-progress-bar-upload>
  </ng-container>

  <ng-container *ngIf="mediaUploadData?.mediaType === 'STREAM_VIDEO'">
    <ptl-resource-stream-video-preview
      *ngIf="mediaUploadSrc"
      [content]="mediaUploadData"
      [newEditor]="newEditor">
    </ptl-resource-stream-video-preview>
    <div *ngIf="!mediaUploadSrc" class="media-upload__stream-video-placeholder">

      <div *ngIf="bytePlaceholder">
        <video controls
               *ngIf="mediaUploadSrc"
               class="media-upload__browser-video"
               [src]="mediaUploadSrc">
        </video>
        <ptl-progress-bar-upload
          *ngIf="!mediaUploadSrc"
          [content]="mediaUploadData">
        </ptl-progress-bar-upload>
      </div>

      <div *ngIf="!bytePlaceholder">
        <ptl-progress-bar-upload
          [content]="mediaUploadData">
        </ptl-progress-bar-upload>
      </div>

    </div>
  </ng-container>

  <ng-container *ngIf="mediaUploadData?.mediaType === 'AUDIO'">
    <audio controls
           *ngIf="!getIsProgressBarVisible"
           class="media-upload__browser-audio"
           [src]="mediaUploadSrc">
    </audio>
    <ptl-progress-bar-upload
      *ngIf="getIsProgressBarVisible"
      [content]="mediaUploadData"
      (contentUploadProgressEmitter)="contentUploadProgress($event)">
    </ptl-progress-bar-upload>
  </ng-container>

  <ng-container *ngIf="mediaUploadData?.mediaType === 'DOCUMENT'">
    <ptl-resource-document-preview
      *ngIf="!getIsProgressBarVisible"
      [isEditingContent]="true"
      [content]="mediaUploadData"
      [pdfDataUrl]="mediaUploadSrc">
    </ptl-resource-document-preview>
    <ptl-progress-bar-upload
      *ngIf="getIsProgressBarVisible"
      [content]="mediaUploadData"
      (contentUploadProgressEmitter)="contentUploadProgress($event)">
    </ptl-progress-bar-upload>
  </ng-container>

  <div class="media-upload__caption-box" *ngIf="mediaUploadData?.caption && !(mediaUploadData?.mediaType === 'STREAM_VIDEO' || mediaUploadData?.mediaType === 'DOCUMENT')">
    <p
      tabindex="0"
      class="caption-text">
      {{mediaUploadData?.caption}}
    </p>
  </div>
</ptl-media-content-view>
