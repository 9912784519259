import { ChartConfiguration } from 'chart.js';

export const defaultAvatarPath = 'assets/profile_image.png';

export function createLineChartConfig(label: string): ChartConfiguration<'line'> {
  return {
    type: 'line',
    data: {
      labels: [],
      datasets: [{
        data: [],
        label,
        borderColor: '#665FD1',
        fill: false
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: { top: 40, left: 20, right: 40, bottom: 20 }
      },
      scales: {
        x: {
          border: { display: false },
          grid: { display: false },
          ticks: {
            font: { family: 'Arial', size: 16 },
            color: '#333'
          }
        },
        y: {
          border: { display: false },
          grid: { display: false },
          beginAtZero: true,
          ticks: { display: false }
        }
      },
      plugins: {
        legend: { display: false },
        datalabels: {
          anchor: 'end',
          align: 'end',
          color: '#000',
          font: { weight: 'bold', size: 16 },
          formatter: (value) => value
        }
      }
    }
  };
}
