/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ptl-most-engagements-chart',
  templateUrl: './most-engagements-chart.component.html',
  styleUrls: ['./most-engagements-chart.component.scss', '../common-analytics-chart-styles.scss']
})
export class MostEngagementsChartComponent implements OnChanges {
  @Input() cardEngagements: Record<string, number>;

  engagementsData: { label: string; count: number }[] = [];
  maxEngagement = 0;
  sortOrder: 'asc' | 'desc' = 'desc';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cardEngagements']) {
      this.buildMostEngagementsData();
    }
  }

  toggleSortOrder(): void {
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    this.engagementsData = this.sortData(this.engagementsData);
  }

  private buildMostEngagementsData(): void {
    if (!this.cardEngagements) {
      this.engagementsData = [];
      this.maxEngagement = 0;
      return;
    }

    const engagements = Object.entries(this.cardEngagements).map(([label, count]) => ({ label, count }));
    this.engagementsData = this.sortData(engagements);
    this.maxEngagement = Math.max(...engagements.map(e => e.count), 0);
  }

  private sortData(data: { label: string; count: number }[]): { label: string; count: number }[] {
    return data.sort((a, b) =>
      this.sortOrder === 'desc' ? b.count - a.count : a.count - b.count
    );
  }
}
