/*
 * Copyright (C) 2019 - present by Potentially
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../../../shared/store';
import { InjectionToken } from '@angular/core';
import {
  GoalRequest,
  GoalResponse,
  BadgeRequest,
  BadgeResponse,
  ObfBadgesListItem
} from '../../../../../shared/models';
import { ReviewSettingsDetails, ReviewSettingsRequest } from '../../../models';
import {
  SubmissionSettingsChangeValidationResult,
  SubmissionSettingsDetails,
  SubmissionSettingsRequest
} from '../../../models/submission-logic.model';
import { Page } from '@app/app/shared/models/page';


/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const GOALS_DATA_SERVICE =
  new InjectionToken<GoalsDataService>('[playlist-goals] GoalsDataService');

/** The service handling playlist publication operations. */
export interface GoalsDataService {

  /**
   * Getting goal from playlist
   *
   * @param playlistUid the UID of the playlist
   */
  getGoal(playlistUid: string, languageCode?: string): ObservableResult<GoalResponse>;

  /**
   * Getting playlist goal
   *
   * @param playlistUid the UID of the playlist
   * @param request goal updated data
   */
  updateGoal(playlistUid: string, request: GoalRequest, languageCode?: string): ObservableResult<GoalResponse>;


  /**
   * Getting playlist badge
   *
   * @param badgeId the id of the gaol badge
   */
  getBadge(badgeId: string, languageCode?: string): ObservableResult<BadgeResponse>;

  /**
   * Creating playlist badge
   *
   * @param request badge updated data
   */
  createBadge(request: BadgeRequest, languageCode?: string): ObservableResult<BadgeResponse>;

  /**
   * Updating playlist badge
   *
   * @param badgeId the id of goal badge
   * @param request badge updated data
   */
  updateBadge(badgeId: string, request: BadgeRequest, languageCode?: string): ObservableResult<BadgeResponse>;

  /**
   * Updating playlist badge active status
   *
   * @param playlistId the id of playlist
   * @param status the active status
   */
  updateBadgeActiveStatus(playlistId: string, status: boolean): ObservableResult<GoalResponse>;

  /**
   * Disconnecting playlist badge
   *
   * @param playlistId the id of playlist
   */
  disconnectBadge(playlistId: string): ObservableResult<GoalResponse>;

  /**
   * Get OBF badges list
   */
  getObfBadgesList(): ObservableResult<ObfBadgesListItem[]>;

  /**
   * Get Card Review settings Detail
   *
   * @param playlistUid the UID of the playlist
   * @param cardUid the UID of the card
   */
  getCardReviewSettingsDetail(playlistUid: string, cardUid: string): ObservableResult<ReviewSettingsDetails>;

  /**
   * Updating Card Review Settings
   *
   * @param playlistUid the UID of the playlist
   * @param cardUid the UID of the card
   * @param request updated review settings data
   */
  updateCardReviewSettings(playlistUid: string, cardUid: string, request: ReviewSettingsRequest): ObservableResult<ReviewSettingsDetails>;

  /**
   * Updating Card Review Settings
   *
   * @param playlistUid the UID of the playlist
   * @param cardUid the UID of the card
   * @param conclude disabled status
   */
  updateCardReviewSettingsDisabledState(playlistUid: string, cardUid: string, conclude: boolean): ObservableResult<void>;

  /**
   * Get Playlist Review settings Detail
   *
   * @param playlistUid the UID of the playlist
   */
  getPlaylistReviewSettingsDetail(playlistUid: string): ObservableResult<ReviewSettingsDetails>;

  /**
   * Updating Playlist Review Settings
   *
   * @param playlistUid the UID of the playlist
   * @param request updated review settings data
   */
  updatePlaylistReviewSettings(playlistUid: string, request: ReviewSettingsRequest): ObservableResult<ReviewSettingsDetails>;

  /**
   * Updating Playlist Review Settings disabled state
   *
   * @param playlistUid the UID of the playlist
   * @param conclude disabled status
   */
  updatePlaylistReviewSettingsDisabledState(playlistUid: string, conclude: boolean): ObservableResult<void>;

  /**
   * Updating Playlist Review Settings enable state
   *
   * @param playlistUid the UID of the playlist
   */
  updatePlaylistReviewSettingsEnableState(playlistUid: string): ObservableResult<void>;

  /**
   * Get Playlist Submission Settings Detail
   *
   * @param playlistUid the UID of the playlist
   */
  getPlaylistSubmissionSettingsDetail(playlistUid: string): ObservableResult<SubmissionSettingsDetails>;

  /**
   * Updating Playlist Submission Settings
   *
   * @param playlistUid the UID of the playlist
   * @param request updated submission settings data
   */
  updatePlaylistSubmissionSettings(playlistUid: string, request: SubmissionSettingsRequest): ObservableResult<SubmissionSettingsDetails>;

  /**
   * Force updating Playlist Submission Settings
   *
   * @param playlistUid the UID of the playlist
   * @param request updated submission settings data
   */
  forceUpdatePlaylistSubmissionSettings(
    playlistUid: string,
    request: SubmissionSettingsRequest
  ): ObservableResult<SubmissionSettingsDetails>;


  /**
   * Validate Playlist Submission Settings change
   *
   * @param playlistUid the UID of the playlist
   * @param request updated submission settings data
   */
  validatePlaylistSubmissionSettingsChange(
    playlistUid: string,
    request: SubmissionSettingsRequest
  ): ObservableResult<SubmissionSettingsChangeValidationResult>;


  /**
   * Get Card Submission Settings Detail
   *
   * @param cardUid the UID of the card
   */
  getCardSubmissionSettingsDetail(playlistUid: string, cardUid: string): ObservableResult<SubmissionSettingsDetails>;

  /**
   * Update Card Submission Settings
   *
   * @param cardUid the UID of the card
   * @param request updated submission settings data
   */
  updateCardSubmissionSettings(
    playlistUid: string, cardUid: string, request: SubmissionSettingsRequest
  ): ObservableResult<SubmissionSettingsDetails>;


  searchBadges(page: number, size: number, term?: string, languageCode?: string): ObservableResult<Page<BadgeResponse>>;
}
