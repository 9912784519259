/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import * as moment from 'moment';
import { TranslationService } from '@app/app/shared/services/translation/translation.service';

interface DateRange {
  start: Date | null;
  end: Date | null;
}

interface PredefinedRange {
  id: PredefinedRangeId;
  label: string;
  range: DateRange;
}

enum PredefinedRangeId {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_7_DAYS = 'last7Days',
  LAST_30_DAYS = 'last30Days',
  THIS_MONTH = 'thisMonth',
  LAST_MONTH = 'lastMonth',
  CUSTOM = 'custom'
}

export enum ComponentDisplayMode {
  INDEPENDENT = 'independent',
  EMBEDDED = 'embedded'
}

@Component({
  selector: 'ptl-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  @Output() filterSaved = new EventEmitter<{ startDate: Date; endDate: Date }>();
  @Input() displayMode: ComponentDisplayMode = ComponentDisplayMode.INDEPENDENT;
  @Input() startDate: Date | null = null;
  @Input() endDate: Date | null = null;

  @ViewChild('startCalendar', { static: false }) startCalendar!: MatCalendar<Date>;
  @ViewChild('endCalendar', { static: false }) endCalendar!: MatCalendar<Date>;

  calendarStartAt: Date | null = null;
  calendarEndAt: Date | null = null;
  selectedRangeId: PredefinedRangeId = PredefinedRangeId.CUSTOM;

  predefinedRanges: PredefinedRange[] = this.getPredefinedRanges();

  constructor(private translationService: TranslationService) {}

  ngOnInit(): void {
    this.calendarStartAt = this.startDate;
    this.calendarEndAt = this.endDate;
  }

  onStartDateSelected(date: Date): void {
    if (this.isPredefinedRangeSelected()) return;

    this.startDate = date;
    this.selectedRangeId = PredefinedRangeId.CUSTOM;
    if (this.endDate && this.endDate < this.startDate) {
      this.endDate = null;
    }
    this.refreshCalendars();
  }

  onEndDateSelected(date: Date): void {
    if (this.isPredefinedRangeSelected()) return;

    if (!this.startDate) return;

    if (date < this.startDate) return;

    this.endDate = date;
    this.selectedRangeId = PredefinedRangeId.CUSTOM;
    this.refreshCalendars();
  }

  selectPredefinedRange(id: PredefinedRangeId): void {
    this.selectedRangeId = id;

    const range = this.predefinedRanges.find((r) => r.id === id)?.range;

    if (range?.start && range?.end) {
      this.startDate = new Date(new Date(range.start).setHours(0, 0, 0, 0));
      this.endDate = new Date(new Date(range.end).setHours(0, 0, 0, 0));
      this.calendarStartAt = new Date(this.startDate);
      this.calendarEndAt = new Date(this.endDate);
    } else {
      this.startDate = null;
      this.endDate = null;
      this.calendarStartAt = null;
      this.calendarEndAt = null;
    }

    this.refreshCalendars();
  }

  dateClass = (date: Date): string => {
    if (!this.startDate || !this.endDate) return '';

    const day = new Date(date.setHours(0, 0, 0, 0)).getTime();
    const start = new Date(this.startDate.setHours(0, 0, 0, 0)).getTime();
    const end = new Date(this.endDate.setHours(0, 0, 0, 0)).getTime();

    if (day === start && start === end) return '';
    if (day === start) return 'selected-date range-start';
    if (day === end) return 'selected-date range-end';
    if (day > start && day < end) return 'selected-date';

    return '';
  };

  clearSelection(): void {
    this.startDate = null;
    this.endDate = null;
    this.selectedRangeId = PredefinedRangeId.CUSTOM;
    this.refreshCalendars();
  }

  applyFilter(): void {
    if (this.startDate && this.endDate) {
      this.filterSaved.emit({ startDate: this.startDate, endDate: this.endDate });
    }
  }

  private refreshCalendars(): void {
    if (this.startCalendar) {
      if (this.selectedRangeId !== PredefinedRangeId.CUSTOM) {
        this.startCalendar.activeDate = this.calendarStartAt || new Date();
      }
      this.startCalendar.updateTodaysDate();
    }
    if (this.endCalendar) {
      if (this.selectedRangeId !== PredefinedRangeId.CUSTOM) {
        this.endCalendar.activeDate = this.calendarEndAt || new Date();
      }
      this.endCalendar.updateTodaysDate();
    }
  }

  private isPredefinedRangeSelected(): boolean {
    return this.selectedRangeId !== PredefinedRangeId.CUSTOM;
  }

  private getPredefinedRanges(): PredefinedRange[] {
    return [
      {
        id: PredefinedRangeId.TODAY,
        label: this.translationService.getTranslation('global.calendar.today'),
        range: { start: moment().toDate(), end: moment().toDate() }
      },
      {
        id: PredefinedRangeId.YESTERDAY,
        label: this.translationService.getTranslation('global.calendar.yesterday'),
        range: { start: moment().subtract(1, 'days').toDate(), end: moment().subtract(1, 'days').toDate() }
      },
      {
        id: PredefinedRangeId.LAST_7_DAYS,
        label: this.translationService.getTranslation('global.calendar.last7Days'),
        range: { start: moment().subtract(6, 'days').toDate(), end: moment().toDate() }
      },
      {
        id: PredefinedRangeId.LAST_30_DAYS,
        label: this.translationService.getTranslation('global.calendar.last30Days'),
        range: { start: moment().subtract(29, 'days').toDate(), end: moment().toDate() }
      },
      {
        id: PredefinedRangeId.THIS_MONTH,
        label: this.translationService.getTranslation('global.calendar.thisMonth'),
        range: { start: moment().startOf('month').toDate(), end: moment().endOf('month').toDate() }
      },
      {
        id: PredefinedRangeId.LAST_MONTH,
        label: this.translationService.getTranslation('global.calendar.lastMonth'),
        range: {
          start: moment().subtract(1, 'month').startOf('month').toDate(),
          end: moment().subtract(1, 'month').endOf('month').toDate()
        }
      },
      {
        id: PredefinedRangeId.CUSTOM,
        label: this.translationService.getTranslation('global.calendar.customRange'),
        range: { start: null, end: null }
      }
    ];
  }

  protected readonly ComponentDisplayMode = ComponentDisplayMode;
}
