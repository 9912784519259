/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { EditorContent, MediaUploadData, MediaUrlData } from '../../../models';

@Component({
  selector: 'ptl-resource-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceImagePreviewComponent implements AfterViewInit {

  /** Receives the EditorContent object */
  @Input() content: EditorContent;

  @ViewChild('htmlEl') public htmlEl: ElementRef;

  ngAfterViewInit() {
    if (this.content && this.content.type === 'MEDIA_URL') {
      this.parseAndAppendHtml((this.content as MediaUrlData).contentHtml);
    }
  }

  private parseAndAppendHtml(html: string) {
    if (html && !!html.length) {
      // createContextualFragment enables the execution of <script> if present in our html
      const fragment = document.createRange().createContextualFragment(html);
      this.htmlEl.nativeElement.appendChild(fragment);
    }
  }

  openUrl() {
    const content = this.content as MediaUrlData;

    if (content.url) {
      window.open(content.url, content.openUrlInTab ? '_blank' : '_self');
    }
  }

  get uploadedImage(): MediaUploadData {
    return this.content as MediaUploadData;
  }
}
