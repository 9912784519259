/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { isEqual } from 'lodash-es';
import {
  CORE_PLAYLIST_DATA_SERVICE,
  PlaylistDataService
} from '../../../page-modules/playlist/services/create/core/data.service';
import { PlaylistViewState } from '../../../page-modules/playlist/store/view/playlist-view.state';
import {
  LoadPlaylistViewDetails,
  LoadProjectPlaylistViewDetails
} from '../../../page-modules/playlist/store/view/playlist-view.state.actions';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { ContentHelper } from '../../helpers/content-helper';
import {
  Breadcrumb,
  CategoryHierarchyItem,
  LoadedCardsMapping,
  LoadedCategoriesMapping,
  PlaylistCardShort,
  ResourceHierarchyItem
} from '../../models';
import { CategoryItem } from '../../models/page/page.model';
import * as BreadcrumbActions from './breadcrumbs.actions';

/** The breadcrumbs state definition. */
export interface BreadcrumbsStateModel {
  /** Elements in the breadcrumbs. */
  breadcrumbs: Breadcrumb[];
  /** Cards loaded from playlists. */
  loadedCards: LoadedCardsMapping;
  /** Loaded categories. */
  loadedCategories: LoadedCategoriesMapping;
  /** Loader for breadcrumb. */
  isLoading: boolean;
}

@State<BreadcrumbsStateModel>({
  name: 'breadcrumbs',
  defaults: {
    breadcrumbs: [],
    loadedCards: {},
    loadedCategories: {},
    isLoading: false
  },
})
@Injectable()
export class BreadcrumbsState {

  @Selector()
  static breadcrumbs({ breadcrumbs }: BreadcrumbsStateModel): Breadcrumb[] {
    return breadcrumbs;
  }

  @Selector()
  static lastBreadcrumb({ breadcrumbs }: BreadcrumbsStateModel): Breadcrumb {
    return breadcrumbs[breadcrumbs.length - 1];
  }

  @Selector()
  static isLoading({ isLoading }: BreadcrumbsStateModel): boolean {
    return isLoading;
  }

  @Selector()
  static resourceHierarchy({ breadcrumbs, loadedCards }: BreadcrumbsStateModel): ResourceHierarchyItem[] {
    const result: ResourceHierarchyItem[] = [];

    for (const breadcrumb of breadcrumbs) {
      if (loadedCards[breadcrumb.url]) {
        result.push({ cards: loadedCards[breadcrumb.url], breadcrumb: breadcrumb });
      }
    }

    return result;
  }

  @Selector()
  static categoryHierarchy({ breadcrumbs, loadedCategories }: BreadcrumbsStateModel): CategoryHierarchyItem[] {
    const result: CategoryHierarchyItem[] = [];

    for (const breadcrumb of breadcrumbs) {
      let categoryUri = '';

      if (breadcrumb.queryParams?.uri) {
        categoryUri = `${breadcrumb.queryParams.uri}/${breadcrumb.queryParams.frameworkId}/${breadcrumb.queryParams.tagId}`;
      }

      if (loadedCategories[categoryUri]) {
        result.push({ categories: loadedCategories[categoryUri], breadcrumb: breadcrumb });
      }
    }

    return result;
  }

  constructor(
    @Inject(CORE_PLAYLIST_DATA_SERVICE) private playlistDataService: PlaylistDataService,
    private activatedRoute: ActivatedRoute,
    private store: Store
  ) {
  }

  @Action(BreadcrumbActions.SetBreadcrumbs)
  setBreadcrumb(
    { patchState }: StateContext<BreadcrumbsStateModel>,
    { breadcrumbs, markCategoriesAsNavigated }: BreadcrumbActions.SetBreadcrumbs
  ) {
    const updatedBreadcrumb = markCategoriesAsNavigated ? breadcrumbs.map(item => {
      const result = { ...item };
      const discoveryPageUri = ContentHelper.getOrgDiscoveryUrl();
      if (result.url.startsWith(discoveryPageUri) || result.url.startsWith(`/v2${discoveryPageUri}`)) {
        const queryParams = result.queryParams ? { ...result.queryParams } : {};
        queryParams.navigate = '1';
        result.queryParams = queryParams;
      }

      return result;
    }) : breadcrumbs;

    patchState({
      breadcrumbs: updatedBreadcrumb,
    });
  }

  @Action(BreadcrumbActions.ClearAllBreadcrumbsStartFromIndex)
  clearBreadcrumbsByIndex(
    { getState, patchState }: StateContext<BreadcrumbsStateModel>,
    { index }: BreadcrumbActions.ClearAllBreadcrumbsStartFromIndex
  ) {

    const state = getState();
    const updatedBreadcrumbs = [...state.breadcrumbs];
    updatedBreadcrumbs.splice(index);
    patchState({
      breadcrumbs: updatedBreadcrumbs,
    });
  }

  @Action(BreadcrumbActions.AddBreadcrumb)
  addBreadcrumb(
    { getState, patchState }: StateContext<BreadcrumbsStateModel>,
    { breadcrumb, categories, params }: BreadcrumbActions.AddBreadcrumb
  ) {
    const state = getState();
    const lastBreadcrumb = state.breadcrumbs[state.breadcrumbs.length - 1];
    let updatedBreadcrumbs = state.breadcrumbs;

    if (
      !lastBreadcrumb ||
      breadcrumb.url !== lastBreadcrumb.url ||
      !isEqual(breadcrumb.queryParams, lastBreadcrumb.queryParams)) {
      updatedBreadcrumbs = updatedBreadcrumbs.concat(breadcrumb);
    }

    patchState({
      breadcrumbs: updatedBreadcrumbs,
    });
    const discoveryPageUri = ContentHelper.getOrgDiscoveryUrl();
    if (categories && (breadcrumb.url === discoveryPageUri ||
      breadcrumb.url === `/v2${discoveryPageUri}` || breadcrumb.url === '/content-store')) {
      this.addCategories(breadcrumb, categories, params, getState, patchState);
    }
  }

  @Action(BreadcrumbActions.AddPlaylistBreadcrumb)
  addPlaylistBreadcrumb(
    { getState, patchState }: StateContext<BreadcrumbsStateModel>,
    { breadcrumb, cards, currentPlaylistUrl }: BreadcrumbActions.AddPlaylistBreadcrumb
  ) {
    const currentCardsMap = getState().loadedCards;
    const updatedBreadcrumbs = [...getState().breadcrumbs];
    const updatedCardsMap: LoadedCardsMapping = {};

    const itemsRemoved = this.removeHierarchyDuplication(updatedBreadcrumbs, currentPlaylistUrl, currentCardsMap);

    for (const item of updatedBreadcrumbs) {
      updatedCardsMap[item.url] = currentCardsMap[item.url];
    }

    if (!itemsRemoved) {
      updatedBreadcrumbs.push(breadcrumb);
      updatedCardsMap[breadcrumb.url] = cards;
    }

    patchState({
      breadcrumbs: updatedBreadcrumbs,
      loadedCards: updatedCardsMap,
    });

    this.store.dispatch(new BreadcrumbActions.ActivateBreadcrumbLoader(true));
  }

  @Action(BreadcrumbActions.ActivateBreadcrumbLoader)
  activateBreadcrumbLoader(
    { patchState }: StateContext<BreadcrumbsStateModel>,
    { active }: BreadcrumbActions.ActivateBreadcrumbLoader
  ) {
    patchState({
      isLoading: active
    });
  }

  @Action(BreadcrumbActions.PopBreadcrumbsByCurrentResourceUrl)
  popBreadcrumbByCurrentResourceUrl(
    { getState, patchState }: StateContext<BreadcrumbsStateModel>,
    { currentResourceUrl }: BreadcrumbActions.PopBreadcrumbsByCurrentResourceUrl
  ) {
    const currentCardsMap = getState().loadedCards;
    const updatedBreadcrumbs = [...getState().breadcrumbs];
    const itemsRemoved = this.removeHierarchyDuplication(updatedBreadcrumbs, currentResourceUrl, currentCardsMap);

    if (itemsRemoved) {
      patchState({
        breadcrumbs: updatedBreadcrumbs,
      });
    }
  }

  @Action(BreadcrumbActions.AddGroupBreadcrumb)
  addGroupBreadcrumb(
    { getState, patchState }: StateContext<BreadcrumbsStateModel>,
    { breadcrumb }: BreadcrumbActions.AddGroupBreadcrumb
  ) {
    const updatedBreadcrumbs = [...getState().breadcrumbs];
    this.removeLastGroupBreadcrumb(updatedBreadcrumbs);
    updatedBreadcrumbs.push(breadcrumb);
    patchState({
      breadcrumbs: updatedBreadcrumbs,
    });
  }

  @Action(BreadcrumbActions.PopGroupBreadcrumbIfPresent)
  popGroupBreadcrumbIfPresent(
    { getState, patchState }: StateContext<BreadcrumbsStateModel>
  ) {
    const updatedBreadcrumbs = [...getState().breadcrumbs];
    const itemsRemoved = this.removeLastGroupBreadcrumb(updatedBreadcrumbs);

    if (itemsRemoved) {
      patchState({
        breadcrumbs: updatedBreadcrumbs,
      });
    }
  }


  @Action(BreadcrumbActions.PopBreadcrumb)
  popBreadcrumb(
    { getState, patchState }: StateContext<BreadcrumbsStateModel>
  ) {

    const updatedBreadcrumbs = [...getState().breadcrumbs];
    updatedBreadcrumbs.pop();

    patchState({
      breadcrumbs: updatedBreadcrumbs,
    });
  }

  @Action(BreadcrumbActions.ClearAllBreadcrumbs)
  clearAllBreadcrumbs(
    { patchState }: StateContext<BreadcrumbsStateModel>
  ) {
    patchState({
      breadcrumbs: [],
    });

    // Removing breadcrumbs array from local storage
    if (localStorage.getItem('savedBreadcrumb')) {
      localStorage.removeItem('savedBreadcrumb');
      localStorage.removeItem('savedLastUrl');
    }
  }

  @Action(BreadcrumbActions.GetParentPlaylistBreadcrumbData)
  getParentPlaylistBreadcrumbData(
    { getState, patchState }: StateContext<BreadcrumbsStateModel>,
    { isProject, playlistUri, loadOnlyCards }: BreadcrumbActions.GetParentPlaylistBreadcrumbData
  ) {
    const publisherUri = this.activatedRoute.snapshot.paramMap.get('publisherUri');
    const packageUri = this.activatedRoute.snapshot.paramMap.get('packageUri');
    const pageUri = this.activatedRoute.snapshot.paramMap.get('pagesUri');
    const folioPublicId = this.activatedRoute.snapshot.paramMap.get('folioPublicId');

    if (loadOnlyCards) {
      this.loadOnlyCards(playlistUri, publisherUri, packageUri, pageUri, getState, patchState);
    } else {
      let playlistSnapshot = this.store.selectSnapshot(PlaylistViewState.playlistState);
      let cards = this.store.selectSnapshot(PlaylistViewState.resourceAllCards);

      if (playlistSnapshot?.data?.uri === playlistUri) {
        const url = isProject ?
          RedirectHelper.getRedirectUrl(this.activatedRoute, {
            folioPublicId: folioPublicId,
            playlistUri: playlistUri,
          }, 'PROJECT') :
          RedirectHelper.getRedirectUrl(this.activatedRoute, {
            playlistUri: playlistSnapshot.data.uri,
            formattedUri: playlistSnapshot.data.formattedUri
          }, 'PLAYLIST');

        this.insertPlaylistBreadcrumb({
          url: url,
          label: `${playlistSnapshot.data.title}`,
        }, getState, patchState, cards);
      } else {
        const observable = isProject ?
          this.store.dispatch(new LoadProjectPlaylistViewDetails(playlistUri, null)) :
          this.store.dispatch(new LoadPlaylistViewDetails(playlistUri, publisherUri, packageUri, pageUri, null));

          observable.subscribe(() => {
            playlistSnapshot = this.store.selectSnapshot(PlaylistViewState.playlistState);
            cards = this.store.selectSnapshot(PlaylistViewState.resourceAllCards);

            if (playlistSnapshot && playlistSnapshot.data) {
              const url = isProject ?
                RedirectHelper.getRedirectUrl(this.activatedRoute, {
                  folioPublicId: folioPublicId,
                  playlistUri: playlistUri,
                }, 'PROJECT') :
                RedirectHelper.getRedirectUrl(this.activatedRoute, {
                  playlistUri: playlistSnapshot.data.uri,
                  formattedUri: playlistSnapshot.data.formattedUri
                }, 'PLAYLIST');
              this.insertPlaylistBreadcrumb({
                url: url,
                label: `${playlistSnapshot.data.title}`,
              }, getState, patchState, cards);
            }
          });
      }
    }
  }

  private loadOnlyCards(
    playlistUri: string,
    publisherUri: string,
    packageUri: string,
    pagesUri: string,
    getState: () => BreadcrumbsStateModel,
    patchState: (val: Partial<BreadcrumbsStateModel>) => BreadcrumbsStateModel
  ) {
    this.playlistDataService.getPlaylistDetails(playlistUri, publisherUri, packageUri, pagesUri)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          const allCards = value.mainSection.cards;

          for (const section of value.standardSections) {
            allCards.push(...section.cards);
          }

          this.insertPlaylistBreadcrumb({
            url: RedirectHelper.getRedirectUrl(this.activatedRoute, { playlistUri: playlistUri }, 'PLAYLIST'),
            label: `${value.mainSection.title}`,
          }, getState, patchState, allCards);
        }
      });
  }

  private addCategories(
    newBreadcrumb: Breadcrumb,
    categories: CategoryItem[],
    currentParams: Params,
    getState: () => BreadcrumbsStateModel,
    patchState: (val: Partial<BreadcrumbsStateModel>) => BreadcrumbsStateModel
  ) {
    const currentCategoriesMap = getState().loadedCategories;
    const updatedBreadcrumbs = [...getState().breadcrumbs];
    const updatedCategoriesMap: LoadedCategoriesMapping = {};
    let categoryUri: string;

    let index = updatedBreadcrumbs.findIndex(
      item => item.queryParams?.uri === currentParams?.uri &&
        item.queryParams?.frameworkId === currentParams?.frameworkId &&
        item.queryParams?.tagId === currentParams?.tagId);

    if (index !== -1 && index < updatedBreadcrumbs.length - 1) {
      updatedBreadcrumbs.splice(index);
    }

    index = updatedBreadcrumbs.findIndex(item => {
      categoryUri = '';

      if (item.queryParams?.uri) {
        categoryUri = `${item.queryParams.uri}/${item.queryParams.frameworkId}/${item.queryParams.tagId}`;
      }

      const result = currentCategoriesMap[categoryUri]?.find(category =>
        category.uri === currentParams?.uri &&
        category.frameworkId === currentParams?.frameworkId &&
        category.tagId === currentParams?.tagId);

      return !!result;
    });

    if (index !== -1) {
      updatedBreadcrumbs.splice(index + 1);
    }

    for (const breadcrumb of updatedBreadcrumbs) {
      categoryUri = '';

      if (breadcrumb.queryParams?.uri) {
        categoryUri = `${breadcrumb.queryParams.uri}/${breadcrumb.queryParams.frameworkId}/${breadcrumb.queryParams.tagId}`;
      }

      updatedCategoriesMap[categoryUri] = currentCategoriesMap[categoryUri];
    }

    categoryUri = '';

    if (newBreadcrumb.queryParams?.uri) {
      categoryUri =
        `${newBreadcrumb.queryParams.uri}/${newBreadcrumb.queryParams.frameworkId}/${newBreadcrumb.queryParams.tagId}`;
    }

    updatedCategoriesMap[categoryUri] = categories;
    patchState({
      breadcrumbs: updatedBreadcrumbs,
      loadedCategories: updatedCategoriesMap,
    });
  }

  private removeLastGroupBreadcrumb(updatedBreadcrumbs: Breadcrumb[]): boolean {
    if (updatedBreadcrumbs.length) {
      const lastBreadcrumb = updatedBreadcrumbs[updatedBreadcrumbs.length - 1];

      if (lastBreadcrumb.url.split('/').length > 3) {
        updatedBreadcrumbs.pop();
        return true;
      }
    }

    return false;
  }

  private removeHierarchyDuplication(
    updatedBreadcrumbs: Breadcrumb[],
    currentResourceUrl: string | undefined,
    cardsMapping: LoadedCardsMapping
  ): boolean {
    let itemsRemoved = false;

    if (updatedBreadcrumbs.length) {
      const lastBreadcrumb = updatedBreadcrumbs[updatedBreadcrumbs.length - 1];
      const splittedCurrentUrl = currentResourceUrl?.split('/');
      const currentPlaylistUri = splittedCurrentUrl ? splittedCurrentUrl[2] : '';
      const currentPlaylistUrl = currentPlaylistUri ? `/playlists/${currentPlaylistUri}` : '';

      const isLastBreadcrumbGroup = lastBreadcrumb.url.split('/').length > 3;
      const isCurrentResourceCard = splittedCurrentUrl ? splittedCurrentUrl.length > 3 : false;

      if (isLastBreadcrumbGroup && !isCurrentResourceCard) {
        updatedBreadcrumbs.pop();
        itemsRemoved = true;
      }

      let index = currentPlaylistUrl ? updatedBreadcrumbs.findIndex(item => item.url.startsWith(currentPlaylistUrl)) : -1;

      if (index === -1 && !isCurrentResourceCard) {
        index = updatedBreadcrumbs
          .findIndex(item => cardsMapping[item.url]?.find(card => card.uri === currentPlaylistUri));

        index = index !== -1 ? index + 1 : index;
      }

      if (index !== -1) {
        if (isCurrentResourceCard) {
          ++index;

          if (isLastBreadcrumbGroup &&
            lastBreadcrumb.url !== currentResourceUrl &&
            lastBreadcrumb.url.startsWith(currentPlaylistUrl)) {
            ++index;
          }
        }

        if (index < updatedBreadcrumbs.length) {
          updatedBreadcrumbs.splice(index);
          itemsRemoved = true;
        } else {
          if (index === 1 && updatedBreadcrumbs.length === 1) {
            updatedBreadcrumbs.splice(index);
            itemsRemoved = true;
          }
        }
      }

      return itemsRemoved;
    } else {
      return false;
    }
  }

  private insertPlaylistBreadcrumb(
    breadcrumb: Breadcrumb,
    getState: () => BreadcrumbsStateModel,
    patchState: (val: Partial<BreadcrumbsStateModel>) => BreadcrumbsStateModel,
    cards?: PlaylistCardShort[]
  ) {
    const updatedBreadcrumbs = [...getState().breadcrumbs];
    const result = updatedBreadcrumbs.find(item => item.url === breadcrumb.url);

    if (!result) {
      let insertionIndex = updatedBreadcrumbs.findIndex(item => item.url.startsWith(breadcrumb.url));

      if (insertionIndex === -1) {
        insertionIndex = updatedBreadcrumbs.length;
      }

      updatedBreadcrumbs.splice(insertionIndex, 0, breadcrumb);

      patchState({
        breadcrumbs: updatedBreadcrumbs,
      });
    }

    if (cards) {
      const updatedCardsMap = { ...getState().loadedCards };
      updatedCardsMap[breadcrumb.url] = cards;

      patchState({
        loadedCards: updatedCardsMap,
      });
    }
  }
}
