<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div *ngIf="mostEngagedUsersLabels.length > 0; else noDataTemplate" class="chart-dashboard">
  <div class="header-row">
    <p class="chart-dashboard-title">{{ 'translations.analytics.charts.mostEngagedUsers.title' | transloco }}</p>
    <button class="btn-sort"
            (click)="toggleSortOrder()"
            [attr.aria-label]="('translations.global.ariaLabels.toggleSorting' | transloco)">
      <mat-icon>{{ sortOrder === 'desc' ? 'arrow_downward' : 'arrow_upward' }}</mat-icon>
    </button>
  </div>

  <div class="chart-wrapper" [ngClass]="{'fixed-height': fixedHeight}">
    <div class="leaderboard">
      <div *ngFor="let user of mostEngagedUsersLabels" class="leaderboard-item">
        <div class="user-info">
          <img [src]="user.imageUrl" class="avatar" alt="User avatar" />
          <div class="user-name">{{ user.firstName }} {{ user.lastName }}</div>
        </div>
        <div class="chart-bar-wrapper" matTooltip="{{ user.firstName }} {{ user.lastName }}">
          <div class="chart-bar-fill" [style.width.%]="(user.engagement / maxEngagement) * 100"></div>
        </div>
        <div class="chart-bar-value">{{ user.engagement }}</div>
      </div>
    </div>
  </div>
</div>

<ng-template #noDataTemplate>
  <div class="placeholder-container" *ngIf="showPlaceholder">
    <mat-icon class="placeholder-icon">person_off</mat-icon>
    <p class="placeholder-message">
      {{ 'translations.analytics.charts.mostEngagedUsers.noUsersFound' | transloco }}
    </p>
    <p class="placeholder-description">
      {{ 'translations.analytics.charts.mostEngagedUsers.noUsersFoundMessage' | transloco }}
    </p>
  </div>
</ng-template>
