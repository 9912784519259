<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->


<div class="skeleton__main-container" [class.skeleton__main-container-cards]="countInput"
  *ngIf="state?.loading; else content" #skeletonContainer>
  <ng-template [ngIf]="showSkeletonView">

    <ng-template [ngIf]="viewType === 'badge-new'">
      <ptl-skeleton-badge [isNew]="true" class="skeleton__item-container--full-width">
      </ptl-skeleton-badge>
    </ng-template>

    <ng-template [ngIf]="viewType === 'badge'">
      <ptl-skeleton-badge class="skeleton__item-container--full-width">
      </ptl-skeleton-badge>
    </ng-template>

    <ng-template [ngIf]="viewType === 'events'">
      <ptl-skeleton-event-item class="skeleton__item-container--full-width"  *ngFor="let item of items">
      </ptl-skeleton-event-item>
    </ng-template>

    <ng-template [ngIf]="viewType === 'report-list'">
      <ptl-skeleton-report-list-item  class="skeleton__item-container--full-width" *ngFor="let item of items">
      </ptl-skeleton-report-list-item>
    </ng-template>

    <ng-template [ngIf]="viewType === 'appointment-list'">
      <ptl-skeleton-appointment-list-item [items]="items" class="skeleton__item-container--full-width">
      </ptl-skeleton-appointment-list-item>
    </ng-template>

    <ng-template [ngIf]="viewType === 'list'">
      <ptl-skeleton-item class="skeleton__item-container" *ngFor="let item of items">
      </ptl-skeleton-item>
    </ng-template>

    <ng-template [ngIf]="viewType === 'action'">
      <ptl-skeleton-action-item class="skeleton__item-action-container" *ngFor="let item of items">
      </ptl-skeleton-action-item>
    </ng-template>

    <ng-template [ngIf]="viewType === 'table'">
      <ptl-skeleton-table-item class="skeleton__item-action-container" *ngFor="let item of items">
      </ptl-skeleton-table-item>
    </ng-template>

    <ng-template [ngIf]="viewType === 'page'">
      <ptl-skeleton-page-item class="skeleton__item-action-container">
      </ptl-skeleton-page-item>
    </ng-template>
  </ng-template>
</div>

<ng-template #content>
  <ng-content *ngIf="!state?.error"></ng-content>
</ng-template>

<ptl-data-load-error-panel
  *ngIf="!!state?.error"
  [hideBackButton]="hideBackButton"
  [errorMessage]="state?.error"
  [dataForms]="state?.dataForms"
  (retry)="onRetryClick()"
></ptl-data-load-error-panel>
