import { VERSION_ENABLED } from '../constants/constants';
import { OrganizationUi } from '@app/app/shared/models';

export class VersionHelper {
  static isVersionEnabled(version: OrganizationUi): boolean {
    return window.localStorage.getItem(`${VERSION_ENABLED}_${version}`) === 'true';
  }

  static isV3Enabled(): boolean {
    return this.isVersionEnabled('v3');
  }

  static setVersionEnabled(version: OrganizationUi, enabled: boolean): void {
    this.clearAllVersions();
    window.localStorage.setItem(`${VERSION_ENABLED}_${version}`, enabled.toString());
  }

  static clearAllVersions(): void {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(VERSION_ENABLED)) {
        window.localStorage.removeItem(key);
      }
    });
  }
}
