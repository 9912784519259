<!--
  ~ Copyright (C) 2025 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div *ngIf="!eventIdsToRegistrationCounts">
  <div *ngIf="chartConfig && chartConfig.data.labels.length !== 0; else noDataTemplate" class="chart-dashboard">
    <p class="chart-dashboard-title">{{ 'translations.analytics.charts.dailyRegistrations.title' | transloco }}</p>
    <p class="chart-dashboard-description">{{ 'translations.analytics.charts.dailyRegistrations.description' | transloco }}</p>

    <canvas baseChart class="chart-wrapper" [ngClass]="{'fixed-height': fixedHeight}"
            [data]="chartConfig.data"
            [options]="chartConfig.options"
            [type]="chartConfig.type">
    </canvas>
  </div>

  <ng-template #noDataTemplate>
    <div class="placeholder-container" *ngIf="showPlaceholder" [ngClass]="{'fixed-height': fixedHeight}">
      <mat-icon class="placeholder-icon">event_busy</mat-icon>
      <p class="placeholder-message">
        {{ 'translations.analytics.charts.dailyRegistrations.noRegistrationsFound' | transloco }}
      </p>
      <p class="placeholder-description">
        {{ 'translations.analytics.charts.dailyRegistrations.noEventRegistrationsMessage' | transloco }}
      </p>
    </div>
  </ng-template>
</div>

<div *ngIf="eventIdsToRegistrationCounts && sortedEventRegistrationData.length > 0" class="chart-dashboard">
  <div class="header-row">
    <p class="chart-dashboard-title">{{ 'translations.analytics.charts.dailyRegistrations.listTitle' | transloco }}</p>
    <button class="btn-sort"
            (click)="toggleSortOrder()"
            [attr.aria-label]="('translations.global.ariaLabels.toggleSorting' | transloco)">
      <mat-icon>{{ sortOrder === 'desc' ? 'arrow_downward' : 'arrow_upward' }}</mat-icon>
    </button>
  </div>

  <div class="chart-wrapper" [ngClass]="{'fixed-height': fixedHeight}">
    <div class="event-registrations">
      <div *ngFor="let data of sortedEventRegistrationData" class="chart-bar">
        <div class="chart-label" matTooltip="{{ data.eventHeader }}">{{ data.eventHeader }}</div>
        <div class="chart-bar-wrapper" matTooltip="{{ data.eventHeader }}">
          <div class="chart-bar-fill" [style.width.%]="(data.count / maxRegistrationCount) * 100"></div>
        </div>
        <div class="chart-bar-value">{{ data.count }}</div>
      </div>
    </div>
  </div>
</div>
