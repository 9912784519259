<!--
  ~ Copyright (C) 2019 - present by Potentially
  ~
  ~ Please see distribution for license.
  -->

<ptl-media-content-view
  [position]="content?.position || 'CENTER'"
  [isExternalContent]="true"
  [canRemoveItem]="canRemoveItem"
  [newEditor]="newEditor"
  (removeMediaContent)="onCloseClick()"
  (mediaContentReplaced)="onReplaceMediaContent($event)"
  (regularLinkReplaced)="onReplaceRegularLink($event)"
  (updateMediaPosition)="onUpdateMediaPosition($event)">
  <div class="content-media__oembed content-media__oembed--disabled" #oembedEl></div>
  <div class="external-content-summary__caption-box">
    <p
      tabindex="0"
      class="caption-text">
      {{ content?.url }}
    </p>
  </div>
</ptl-media-content-view>

