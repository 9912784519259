<!--
  ~ Copyright (C) 2025 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="date-filter-container"
  [ngClass]="{'embedded': displayMode === ComponentDisplayMode.EMBEDDED}"
>
  <mat-card
    class="date-filter-settings"
    [ngClass]="{'embedded': displayMode === ComponentDisplayMode.EMBEDDED}"
  >
    <div class="date-filter-settings__content"
         [ngClass]="{'embedded': displayMode === ComponentDisplayMode.EMBEDDED}">
      <div class="predefined-ranges">
        <button
          *ngFor="let range of predefinedRanges"
          mat-button
          color="primary"
          class="predefined-range-button"
          [class.selected]="selectedRangeId === range.id"
          (click)="selectPredefinedRange(range.id)"
        >
          {{ range.label }}
        </button>
      </div>

      <div class="separator"></div>

      <div class="calendar-container">
        <mat-calendar
          #startCalendar
          [startAt]="calendarStartAt"
          [selected]="startDate"
          [startView]="'month'"
          [dateClass]="dateClass"
          (selectedChange)="onStartDateSelected($event)"
        ></mat-calendar>

        <mat-calendar
          #endCalendar
          [startAt]="calendarEndAt"
          [selected]="endDate"
          [startView]="'month'"
          [dateClass]="dateClass"
          (selectedChange)="onEndDateSelected($event)"
        ></mat-calendar>
      </div>
    </div>

    <div class="date-filter-settings__footer">
      <button
        mat-stroked-button
        (click)="clearSelection()"
        [attr.aria-label]="('translations.global.button.clear' | transloco)"
      >
        {{ 'translations.global.button.clear' | transloco }}
      </button>
      <button
        mat-stroked-button
        (click)="applyFilter()"
        [attr.aria-label]="('translations.global.button.apply' | transloco)"
      >
        {{ 'translations.global.button.apply' | transloco }}
      </button>
    </div>
  </mat-card>
</div>
