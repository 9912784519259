<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="page-analytics__container" *ngIf="!showSkeletonView">
  <h1 [attr.aria-label]="('translations.analytics.title.main' | transloco)">
    {{ 'translations.analytics.title.main' | transloco }}
  </h1>

  <div class="page-analytics__summary">
    <p class="page-analytics__summary-title">
      {{ 'translations.courses' | transloco }}
    </p>

    <ptl-control-panel-filter-chips
      [filters]="filters"
      [currentFilter]="currentFilter"
      [newFilter]="newFilter"
      [playlistUids]="playlistUids"
      [supportedFilters]="['GROUP', 'ROLE', 'ACTIVITY', 'REVIEW_STATUS']"
      (filterSelected)="filterSelected($event)"
      (filterDeleted)="onRemoveFilter($event)"
      (filterSaved)="onSaveFilter($event)">
    </ptl-control-panel-filter-chips>

    <div class="control-panel-buttons">
      <ptl-filter-btn class="filter-button" [labels]="courseFilterLabels" (filterSelected)="filterSelected($event)">
      </ptl-filter-btn>
      <!-- TODO: Export button will be handled in next iteration
      <ptl-export-btn class="filter-button">
      </ptl-export-btn>
      -->
    </div>
  </div>

  <div class="metrics-row-3-3-1">

    <div class="metric-card metric-card--main">
      <div class="metric-icon">
        <mat-icon>start</mat-icon>
      </div>
      <div class="metric-name concise">{{ 'translations.analytics.metric.usersHaveStarted' | transloco }}</div>
      <div class="metric-value">{{ startedCourseCount }}</div>
      <div class="metric-name concise">
        {{
          startedCourseCount | i18nPlural: {
            '=1': 'translations.analytics.metric.course' | transloco,
            'other': 'translations.analytics.metric.courses' | transloco
          }
        }}
      </div>
    </div>

    <div class="metric-card metric-card--main">
      <div class="metric-icon">
        <mat-icon>task_alt</mat-icon>
      </div>
      <div class="metric-name concise">{{ 'translations.analytics.metric.usersHaveCompleted' | transloco }}</div>
      <div class="metric-value">{{ completedCourseCount }}</div>
      <div class="metric-name concise">
        {{
          completedCourseCount | i18nPlural: {
            '=1': 'translations.analytics.metric.course' | transloco,
            'other': 'translations.analytics.metric.courses' | transloco
          }
        }}
      </div>
    </div>

    <div class="metric-card link-card"
         (click)="openDetailsDialog()"
         (keydown.enter)="openDetailsDialog()">
      <div class="metric-link-icon">
        <mat-icon>table_chart</mat-icon>
      </div>
      <p class="link-button">{{ 'translations.analytics.button.openTableView' | transloco }}</p>
    </div>

  </div>

  <div class="metrics-row-1-4">
    <div class="metrics-column">
      <div class="metric-card metric-card--small">
        <div class="metric-icon">
          <mat-icon>group</mat-icon>
        </div>
        <div class="metric-value">{{ this.membersCount }}</div>
        <div class="metric-name">
          {{
            this.membersCount | i18nPlural: {
              '=1': 'translations.analytics.metric.member' | transloco,
              'other': 'translations.analytics.metric.totalMembers' | transloco
            }
          }}
        </div>
      </div>
      <div class="metric-card metric-card--small">
        <div class="metric-icon">
          <mat-icon>note</mat-icon>
        </div>
        <div class="metric-value">{{ this.totalCardsCount }}</div>
        <div class="metric-name">
          {{
            this.totalCardsCount | i18nPlural: {
              '=1': 'translations.analytics.metric.card' | transloco,
              'other': 'translations.analytics.metric.cards' | transloco
            }
          }}
        </div>
      </div>
    </div>
    <ptl-course-engagements-chart
      class="metric-card--large"
      [individualPlaylistMetrics]="individualPlaylistMetrics"
      [fixedHeight]="true"
      [showPlaceholder]="true">
    </ptl-course-engagements-chart>
  </div>

  <div class="spacer"></div>

  <ng-container *ngIf="metricsBundle.cardEventsInfo.length > 0">

    <div class="page-analytics__summary">
      <p class="page-analytics__summary-title">
        {{ 'translations.events' | transloco }}
      </p>
      <ptl-event-search
        [cardEventsInfo]="metricsBundle.cardEventsInfo"
        [selectedEventId]="selectedEventId"
        (eventSelected)="onEventSelected($event)">
      </ptl-event-search>

      <div class="control-panel-buttons">
        <ptl-control-panel-filter-chips
          [filters]="filters"
          [currentFilter]="currentFilter"
          [newFilter]="newFilter"
          [playlistUids]="playlistUids"
          [supportedFilters]="['ATTENDANCE']"
          (filterSelected)="filterSelected($event)"
          (filterDeleted)="onRemoveFilter($event)"
          (filterSaved)="onSaveFilter($event)">
        </ptl-control-panel-filter-chips>
        <ptl-filter-btn class="filter-button" [labels]="eventFilterLabels" (filterSelected)="filterSelected($event)">
        </ptl-filter-btn>
      </div>
    </div>

    <div class="metrics-column">

      <div class="metrics-row-1-1-3">
        <div class="metrics-column metric-card--left">
          <div class="metric-card top-left-radius">
            <div class="metric-icon">
              <mat-icon>event</mat-icon>
            </div>
            <div class="metric-value">{{ eventCardCount }}</div>
            <div class="metric-name">
              {{
                eventCardCount | i18nPlural: {
                  '=1': 'translations.event' | transloco,
                  'other': 'translations.events' | transloco
                }
              }}
            </div>
          </div>

          <div class="metric-card bottom-left-radius">
            <div class="metric-icon">
              <mat-icon>person_add</mat-icon>
            </div>
            <div class="metric-value">{{ registeredUsers }}</div>
            <div class="metric-name">
              {{
                registeredUsers | i18nPlural: {
                  '=1': 'translations.analytics.metric.registration' | transloco,
                  'other': 'translations.analytics.metric.registrations' | transloco
                }
              }}
            </div>
          </div>
        </div>

        <div class="metrics-column metric-card--left">
          <div class="metric-card top-right-radius">
            <div class="metric-icon">
              <mat-icon>event_available</mat-icon>
            </div>
            <div class="metric-value">{{ participatedUsers }}</div>
            <div class="metric-name">{{ 'translations.analytics.metric.participated' | transloco }}</div>
          </div>

          <div class="metric-card bottom-right-radius">
            <div class="metric-icon">
              <mat-icon>hourglass_empty</mat-icon>
            </div>
            <div class="metric-value">{{ waitlistedUsers }}</div>
            <div class="metric-name">{{ 'translations.analytics.metric.waitlisted' | transloco }}</div>
          </div>
        </div>

        <ptl-daily-enrollment-chart
          [dailyEnrollmentCounts]="registrationDatesToCounts"
          [eventIdsToRegistrationCounts]="eventIdsToRegistrationCounts"
          [cardEventsInfo]="metricsBundle.cardEventsInfo"
          [fixedHeight]="true"
          [showPlaceholder]="true">
        </ptl-daily-enrollment-chart>
      </div>

    </div>

  </ng-container>

</div>

<ng-container *ngIf="showSkeletonView">

  <ng-container *ngIf="!hasRelevantPlaylists">
    <div class="page-analytics__no-relevant-playlists">
      <p>{{ 'translations.analytics.info.emptyState' | transloco }}</p>
      <img type="image/svg+xml"
           src="assets/placeholder/analytics-chart.svg"
           [attr.aria-label]="('translations.global.ariaLabels.barChart' | transloco)" alt="">
    </div>
  </ng-container>

  <ng-container *ngIf="hasRelevantPlaylists">
    <div class="page-analytics__loading-warning">
      {{ 'translations.analytics.info.loadingWarning' | transloco }}
    </div>
    <ptl-skeleton-view [state]="{ loading: true }" [viewType]="'action'"></ptl-skeleton-view>
  </ng-container>

</ng-container>
