/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { OrganizationRetrievalDataService } from './organization-retrieval-data.service';
import { JiscOrganization, Organization, OrganizationFeature } from '../../models';
import { ObservableResult } from '../../store';
import { RestClientService } from '../rest-client.service';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { switchMap, catchError } from 'rxjs/operators';
import { TranslationService } from '../translation/translation.service';
import { Page } from '../../models/page/page.model';
import { Page as Pagination } from '../../models/page';
import { Publisher } from '../../models/content-store/content-store.model';

export class ApiOrganizationRetrievalDataService implements OrganizationRetrievalDataService {

  constructor(private restClient: RestClientService, private translationService: TranslationService) {
  }

  findOrganizationForDomain(domain: string): ObservableResult<Organization> {
    return this.restClient.get<Organization>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `organizations?domain=${domain}`))
      .pipe(
        switchMap(res => ObservableResult.ofSuccess(res.body)),
        catchError(val => ObservableResult.ofError(val)));
  }


  addDomainToWhitelist(domain: string): ObservableResult<void> {
    return this.restClient.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'organizations/whitelist'),
      null,
      { email: domain })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorAddDomainToWhitelist'))));
  }

  removeDomainFromWhitelist(domain: string): ObservableResult<void> {
    return this.restClient.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'organizations/whitelist'),
      { email: domain })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.removeDomainFromWhitelist'))));
  }

  clearWhitelistDomains(): ObservableResult<void> {
    return this.restClient.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'organizations/whitelist/clear'))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.clearWhitelistDomains'))));
  }


  addDomainToBlacklist(domain: string): ObservableResult<void> {
    return this.restClient.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'organizations/blacklist'),
      null,
      { email: domain })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorAddDomainToBlacklist'))));
  }

  removeDomainFromBlacklist(domain: string): ObservableResult<void> {
    return this.restClient.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'organizations/blacklist'),
      { email: domain })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.removeDomainFromBlacklist'))));
  }

  clearBlacklistDomains(): ObservableResult<void> {
    return this.restClient.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'organizations/blacklist/clear'))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.clearBlacklistDomains'))));
  }

  searchJiscOrganizations(page: number, size: number, term: string): ObservableResult<Pagination<JiscOrganization>> {
    const params = {
      page: page.toString(),
      size: size.toString(),
    }
    if (term) {
      params['term'] = term;
    }
    return this.restClient.get<Pagination<JiscOrganization>>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'organizations/jisc'),
      params
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(({ error }) => ObservableResult.ofError(error?.message)));
  }


  getHomePage(isAlumni?: boolean, languageCode?: string): ObservableResult<Page> {
    return this.restClient.get<Page>(
      Location.joinWithSlash(environment.apiRootUrl || '', isAlumni ? 'pages/alumni' : 'pages/home'),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetHomePage'))));
  }

  getCurrentOrganizationPublisher(): ObservableResult<Publisher> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', 'store/publishers');
    return this.restClient.get<Publisher>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGettingPublisher')))
      );
  }

  checkFeatureForOrganization(orgUid: string, feature: string): ObservableResult<boolean> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `features/${feature}/organizations/${orgUid}/check`);
    return this.restClient.get<boolean>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGettingFeature')))
      );
  }

  checkPublicFeatureForOrganization(orgUid: string, feature: string): ObservableResult<boolean> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `features/public/${feature}/organizations/${orgUid}/check`);
    return this.restClient.get<boolean>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGettingFeature')))
      );
  }

  getOrganizationFeatures(orgUid: string): ObservableResult<OrganizationFeature[]> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `features/organizations/${orgUid}/check`);
    return this.restClient.get<OrganizationFeature[]>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGettingOrganizationFeatures')))
      );
  }
}
