/*
 * Copyright (C) 2018 - present by Potentially
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { developmentDomains } from '../../../../../shared/helpers/development-domains.helper';
import { Organization } from '../../../../../shared/models';
import { FeatureCheckModel } from '../../../../../user-auth/models/feature-check.model';
import { CheckOrganizationFeature } from '../../../../../user-auth/store/user-auth.actions';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { PAGES_DATA_SERVICE, PageDataService } from '../../../services/data.service';


@Component({
  selector: 'ptl-page-tableau-section',
  templateUrl: './page-tableau-section.component.html',
  styleUrls: ['./page-tableau-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTableauSectionComponent implements OnInit, OnDestroy {

  @Input() pageUid: string;
  @Input() languageCode: string;

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  @Select(UserAuthState.tableauV2FeatureFlagEnabled)
  private tableauV2FeatureFlagEnabled$: Observable<boolean>;

  // V1
  iframeSrc: string | null;
  dataIsLoaded: boolean;

  // V2
  tableauV2FeatureFlagEnabled = false; // todo to remove
  JISC_TABLEAU_URL = 'https://prod-uk-a.online.tableau.com/t/jiscembeddedtableauanalytics/views/DigitalCapabilitiesDashboard/Welcomepage';
  connectedAppToken: string;

  private isDevelopmentDomains: boolean;
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private store: Store,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    @Inject(PAGES_DATA_SERVICE) private pageDataService: PageDataService,
  ) {
  }

  ngOnInit() {
    this.store.dispatch(new CheckOrganizationFeature(FeatureCheckModel.TABLEAU_V2_ENABLED));

    this.dataIsLoaded = false;

    this.organizationData$.pipe(
      takeUntil(this.subscriptionEnd$)
    ).subscribe((organization) => {
      this.isDevelopmentDomains = developmentDomains(organization?.domain);
      this.getTableauUrl();
    });

    this.tableauV2FeatureFlagEnabled$.pipe(
      takeUntil(this.subscriptionEnd$)
    ).subscribe((value) => {
      if (value) {
        this.tableauV2FeatureFlagEnabled = value;
        this.initializeTableauContent();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionEnd$?.emit();
  }

  private initializeTableauContent(): void {
    if (this.pageUid) {
      this.pageDataService.getTableauTokenV2(this.pageUid).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.connectedAppToken = value.token;
          this.addMissingWidth();
        }
        this.dataIsLoaded = true;
        this.cdr.detectChanges();
      });
    }
  }

  private addMissingWidth(): void {
    setTimeout(() => {
      const layoutContainer = document.querySelector('.f-page-layout__container') as HTMLElement;
      const buttonsContainer = document.querySelector('.f-content-view-admin-buttons-container') as HTMLElement;
      const missingWidth = 48;

      if (layoutContainer) {
          layoutContainer.style.maxWidth = `${layoutContainer.clientWidth + missingWidth}px`;
      }

      if (buttonsContainer) {
          buttonsContainer.style.maxWidth = `${buttonsContainer.clientWidth + missingWidth}px`;
      }
    }, 300);
  }

  private getTableauUrl() {
    const params = ':embed=yes&:toolbar=yes&:toolbar=top&:refresh=yes&PasswordParameter=Enter%20password&:customViews=no';
    let server = 'charts.jisc.ac.uk';
    let viewUrl = 't/EDW/views/DigitalCapabilitiesDashboard/Welcomepage';

    if (this.isDevelopmentDomains) {
      server = 'uat.charts.jisc.ac.uk';
      viewUrl = 't/EDWUAT/workbooks/721/views';
    }

    if (this.pageUid) {
      this.getTableauToken(server, params, viewUrl);
    }
  }

  private getTableauToken(server: string, params: string, viewUrl: string) {
    this.pageDataService.getTableauToken(this.pageUid, this.languageCode).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        const token = value.token;
        this.iframeSrc = this.getPreviewUrl(`https://${server}/trusted/${token}/${viewUrl}?${params}`) as string;
      } else {
        this.iframeSrc = null;
      }
      this.cdr.detectChanges();
      this.dataIsLoaded = true;
    });
  }

  private getPreviewUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
