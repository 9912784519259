<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="search-container">
  <mat-form-field appearance="outline" class="search">
    <mat-icon matPrefix>search</mat-icon>

    <input
      matInput
      [(ngModel)]="searchQuery"
      (focus)="onSearchFocus()"
      (input)="onSearchChange()"
      maxlength="64"
      placeholder="{{ 'translations.analytics.filter.specifyEvent' | transloco  }}"
      [readonly]="!!selectedEventId" />

    <button
      mat-icon-button
      matSuffix
      (click)="clearSelection()"
      [ngClass]="{'active': selectedEventId, 'hidden': !selectedEventId}">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div class="search-results" *ngIf="isDropdownVisible && filteredEvents.length">

    <mat-option *ngFor="let event of filteredEvents" [value]="event"
                (click)="onEventSelect(event.id)"
                [matTooltip]="event.name" class="search-result-event-option">
      <div class="search-result-event">
        <span class="event-title">{{ event.name }}</span>
      </div>
    </mat-option>
  </div>
</div>
