<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="stream-video-preview-element stream-video-preview-element-{{ content?.type }}"
  [ngClass]="[
    'stream-video-preview-element--' + content?.type,
    !isEditMode ? 'stream-video-preview-element-position-' + (content?.position || 'CENTER') : ''
  ]">
  <div *ngIf="isProcessing" class="loading-container">
    <div class="loading-container__processing-info">{{ 'translations.videoProcessingInfo' | transloco }}</div>
    <div class="spinner"></div>
  </div>
  <div *ngIf="isProgressBarVisible" class="loading-container">
    <ptl-progress-bar-upload
      [content]="content"
      (contentUploadProgressEmitter)="contentUploadProgress($event)">
    </ptl-progress-bar-upload>
  </div>
  <video #video playsinline controls
    class="stream-video-preview-element--video"
    type="application/x-mpegURL"
    [poster]="posterUrl"
    [hidden]="!playerInitialized || isProcessing">
  </video>
  <div class="stream-video-preview-element__caption-box" *ngIf="content?.caption">
    <p
      tabindex="0"
      class="caption-text"
      role="button">
      {{content?.caption}}
    </p>
  </div>
</div>
