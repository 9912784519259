/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {BlockUpdateResponse, ResourceDataService, ResourceUpdateSectionResponse} from './data.service';
import { CardTitleUpdateRequest, ResourceSectionRequest, ResourceThumbnailUpdateRequest } from '../../../models/editor';
import { ObservableResult } from '../../../../../shared/store';
import { Injectable } from '@angular/core';
import {
	EditorContent,
	OrganizationDetailsSummary,
	PlaylistCardShort,
	Resource,
	ResourceSection
} from '../../../../../shared/models';
import { EditorContentType, MediaUrlData } from '../../../../../editor/models';
import { delay } from 'rxjs/operators';
import { CreateGroupRequest } from '../../../../playlist/models';
import { ItemWithUploadUrl } from '../../../../../shared/models/ItemWithUploadUrl';
import { CardsStatsSummary, StatsData } from '../../../../../shared/models/analytics/analytics.model';
import { Page } from '../../../../../shared/models/page';
import { AllContentCard, AllContentPlaylist } from '../../../../../shared/models/all-content/all-content.model';

@Injectable()
export class MockResourceDataService implements ResourceDataService {

	private readonly organization: OrganizationDetailsSummary = {
		_id: 'uuid2',
		name: 'Royal Holliday'
	};

	private dummyExistingResource: Resource = {
		_id: 'uid1',
		organization: {
			_id: 'company1',
			name: 'University of Leicester'
		},
		likes: {
			vote: true,
			totalLikes: 19
		},
		content: {
			headline: 'The first Resource',
			sections: [{
				uid: 'main sectionUid',
				dynamicContent: [{
					uid: 'contentUid1',
					type: 'IMAGE_URL' as EditorContentType,
					contentUrl: '',
					contentHtml: '<div><span>Nice one</span></div>'
				} as MediaUrlData
				]
			}, {
				uid: 'section UId',
				dynamicContent: [{
					uid: 'contentUid1',
					type: 'IMAGE_URL' as EditorContentType,
					contentUrl: '',
					contentHtml: '<div><span>Nice one</span></div>'
				} as MediaUrlData]
			}]
		},
		status: 'PUBLISHED',
		childOfInstitutionalLibrary: false,
		settings: {
			publication: {
				author: {
					name: 'James Allen',
					uid: 'fooBar',
					organization: 'Uni A',
					displayConfig: {
						authorName: true,
						organizationName: true
					},
					orgLogoUrl: 'https://static.yuna.potential.ly/public/images/icons/potentially_logo.png',
					userImageUrl: 'assets/profile_image.png'
				},
				uri: 'uri'
			}
		},
		formattedUri: 'uri',
		permission: {
			canEdit: false,
			canPublish: true,
			canDoMemberExports: true,
			canViewAnalytics: true
		},
		lastUpdatedOn: '2019-04-15T18:55:12.549',
		subHeader: null
	};

	updateCardHeadline( resourceUid: string, request: CardTitleUpdateRequest ): ObservableResult<Resource> {
		this.dummyExistingResource = {
			_id: resourceUid,
			organization: this.organization,
			content: {
				headline: request.headline,
				sections: this.dummyExistingResource.content.sections
			},
			status: 'PUBLISHED',
			likes: {
				vote: true,
				totalLikes: 18
			},
			permission: {
				canEdit: false,
				canPublish: true,
				canDoMemberExports: true,
				canViewAnalytics: true
			},
			childOfInstitutionalLibrary: false,
			formattedUri: 'uri',
			lastUpdatedOn: '2019-04-15T18:55:12.549',
			subHeader: null
		};

		return ObservableResult.ofSuccess(this.dummyExistingResource);
	}

	markWebLinkOpened( resourceUid: string ): ObservableResult<void> {
		return ObservableResult.ofSuccess();
	}

	getResourceDetails(
		isProject: boolean,
		playlistUri: string,
		resourceUri: string,
		groupUri: string,
		publisherUri: string,
		packageUri: string,
		pageUri: string,
		languageCode?: string
	): ObservableResult<Resource> {
		return ObservableResult.ofSuccess(this.dummyExistingResource).pipe(
			delay(1500));
	}

	deleteResource( playlistUid: string, sectionUid: string, resourceUid: string ): ObservableResult<void> {
		return ObservableResult.ofSuccess();
	}

	createSection( resourceUid: string ): ObservableResult<ResourceSection> {
		return ObservableResult.ofSuccess();
	}

	createEditorBlock( cardUid: string, sectionUid: string, data: EditorContent, languageCode?: string ):
		ObservableResult<BlockUpdateResponse> {
		return ObservableResult.ofSuccess();
	}

	updateEditorBlock( cardUid: string, sectionUid: string, data: EditorContent, languageCode?: string )
		: ObservableResult<BlockUpdateResponse> {
		return ObservableResult.ofSuccess();
	}

	deleteEditorBlock( cardUid: string, sectionUid: string, blockUid: string ): ObservableResult<void> {
		return ObservableResult.ofSuccess();
	}

	deleteSection( resourceUid: string, sectionUid: string ): ObservableResult<Resource> {
		return ObservableResult.ofSuccess();
	}

	updateSection( resourceUid: string, sectionUid: string, data: ResourceSectionRequest, languageCode?: string )
		: ObservableResult<ResourceUpdateSectionResponse> {
		return ObservableResult.ofSuccess();
	}

	updateTimeRequired( resourceUid: string, timeRequired: number ): ObservableResult<void> {
		return ObservableResult.ofSuccess();
	}

	getResourceTimeRequiredSuggestion( resourceUid: string, languageCode?: string ): ObservableResult<number> {
		return ObservableResult.ofSuccess(0);
	}

	createGroupCards( playlistUid: string, sectionUid: string, request: CreateGroupRequest ): ObservableResult<void> {
		return ObservableResult.ofSuccess();
	}

	updateGroupCards( playlistUid: string, groupUid: string, request: CreateGroupRequest, languageCode?: string ): ObservableResult<void> {
		return ObservableResult.ofSuccess();
	}

	changeResourceThumbnail(
		resourceUid: string,
		request: ResourceThumbnailUpdateRequest
	): ObservableResult<ItemWithUploadUrl<Resource>> {
		return ObservableResult.ofSuccess();
	}

	removeResourceThumbnail( resourceUid: string ): ObservableResult<void> {
		return ObservableResult.ofSuccess();
	}

	archiveResource( resourceUid: string ): ObservableResult<void> {
		return ObservableResult.ofSuccess();
	}

	addLanguage( cardUid: string, languageCode: string ): ObservableResult<void> {
		return ObservableResult.ofSuccess();
	}

	removeLanguage( cardUid: string, languageCode: string ): ObservableResult<void> {
		return ObservableResult.ofSuccess();
	}

	enableLanguage( cardUid: string, languageCode: string ): ObservableResult<void> {
		return ObservableResult.ofSuccess();
	}

	disableLanguage( cardUid: string, languageCode: string ): ObservableResult<void> {
		return ObservableResult.ofSuccess();
	}


	getResourceStartedStatistics( cardUid: string, playlistUid: string, from: string, to: string ): ObservableResult<StatsData[]> {
		return ObservableResult.ofSuccess([]);
	}

	getResourceCompletedStatistics( cardUid: string, playlistUid: string, from: string, to: string ): ObservableResult<StatsData[]> {
		return ObservableResult.ofSuccess([]);
	}

	getResourceStatisticsSummary( cardUid: string, playlistUid: string ): ObservableResult<CardsStatsSummary> {
		return ObservableResult.ofSuccess({
			started: 10,
			completed: 5
		});
	}

	getResourceCardsByTags( page: number, size: number, tags: string[] ): ObservableResult<Page<PlaylistCardShort>> {
		return ObservableResult.ofSuccess();
	}

	getFormPlayListByUid( playlistUid: string, term: string, page: number, size: number ): ObservableResult<Page<AllContentPlaylist>> {
		return ObservableResult.ofSuccess(
			{
				currentPageNumber: 0,
				hasContent: true,
				numberOfPages: 3,
				pageSize: 20,
				totalNumberOfElement: 60,
				content: []
			}
		);
	}

	getFormCardsByPlayListUid( playlistUid: string, selectedPlaylistUid: string ): ObservableResult<AllContentCard[]> {
		return ObservableResult.ofSuccess();
	}

	getFormsList( playlistUid: string, selectedPlaylistUid: string, selectedCardUid: string ):
		ObservableResult<{ title: string; id: string }[]> {
		return ObservableResult.ofSuccess();
	}

	getFormTitlesByPlayListUid( playlistUid: string ): ObservableResult<{ title: string }> {
		return ObservableResult.ofSuccess();
	}

	getFormCardTitlesByCardUid( playlistUid: string, cardUid: string ): ObservableResult<{ title: string }> {
		return ObservableResult.ofSuccess();
	}
}
