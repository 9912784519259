<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div>
  <ptl-date-range-picker
    class="filter-settings"
    (filterSaved)="onDateRangeSelected($event)">
  </ptl-date-range-picker>
</div>
