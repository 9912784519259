<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<div class="activity-date-filter-container">
  <mat-card
    *ngIf="expanded"
    class="activity-date-filter-settings"
    [ngClass]="{'in-range': filterType === 'IN_RANGE'}">

    <div class="activity-date-filter-settings__header">
      <div class="double-button-box">
        <button mat-button [matMenuTriggerFor]="booleanMenu" class="isBtn">
          <span class="small bold">
            {{ activityIs ? ('translations.filter.has' | transloco) : ('translations.filter.hasNot' | transloco) }}
          </span>
          <mat-icon aria-hidden="true" class="icon">expand_more</mat-icon>
        </button>
        <span class="small">{{ 'translations.filter.activity.beenActive' | transloco }}</span>
        <button mat-button [matMenuTriggerFor]="filterTypeMenu" class="isBtn">
          <span *ngIf="filterType === 'SINCE'"
                class="small bold">{{ 'translations.global.ariaLabels.since' | transloco }}</span>
          <span *ngIf="filterType === 'FOR_THE_LAST'"
                class="small bold">{{ 'translations.global.ariaLabels.forTheLast' | transloco }}</span>
          <span *ngIf="filterType === 'IN_RANGE'"
                class="small bold">{{ 'translations.global.ariaLabels.inRange' | transloco }}</span>
          <mat-icon aria-hidden="true" class="icon">expand_more</mat-icon>
        </button>
      </div>
      <span class="activity-date-filter-delete" (click)="deleteFilter()" (keydown.enter)="deleteFilter()">
        <svg class="svg" role="img" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
        </svg>
      </span>
    </div>
    <div class="activity-date-filter-settings__content">
      <mat-calendar
        *ngIf="filterType === 'SINCE'"
        [startAt]="date"
        [selected]="date"
        (selectedChange)="dateChanged($event)"
      ></mat-calendar>
      <div *ngIf="filterType === 'FOR_THE_LAST'" class="for-the-last-content">
        <input
          class="quantity-field"
          type="number"
          min="1"
          [attr.aria-label]="('translations.global.ariaLabels.enterTicketsQuantity' | transloco)"
          [(ngModel)]="quantity">
        <mat-select
          class="small bold select-field"
          [attr.aria-label]="('translations.global.ariaLabels.selectPeriodType' | transloco)"
          [(ngModel)]="periodType">
          <mat-option [value]="'DAY'" class="small">
            {{ 'translations.filter.activity.days' | transloco }}
          </mat-option>
          <mat-option [value]="'WEEK'" class="small">
            {{ 'translations.filter.activity.weeks' | transloco }}
          </mat-option>
          <mat-option [value]="'MONTH'" class="small">
            {{ 'translations.filter.activity.months' | transloco }}
          </mat-option>
          <mat-option [value]="'YEAR'" class="small">
            {{ 'translations.filter.activity.years' | transloco }}
          </mat-option>
        </mat-select>
      </div>
      <div *ngIf="filterType === 'IN_RANGE'">
        <ptl-date-range-picker class="in-range-content"
                               (filterSaved)="onDateRangeSelected($event)"
                               [displayMode]="ComponentDisplayMode.EMBEDDED"
                               [startDate]="this.startDate"
                               [endDate]="this.endDate">
        </ptl-date-range-picker>
      </div>
      <div class="activity-date-filter-settings__footer" [ngClass]="{'in-range': filterType === 'IN_RANGE'}">
        <button
          mat-stroked-button
          class="activity-date-filter-settings__btn"
          (click)="applyFilter()">
          {{ 'translations.global.button.apply' | transloco }}
        </button>
      </div>
    </div>
  </mat-card>
</div>
<mat-menu #booleanMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="setActivityIs()"
            tabindex="0"
            class="is-item"
            [attr.aria-label]="('translations.global.ariaLabels.activityIs' | transloco)">
      <span class="small">
        {{ 'translations.filter.has' | transloco }}
      </span>
    </button>
    <button mat-menu-item (click)="setActivityIsNot()"
            tabindex="0"
            class="is-item"
            [attr.aria-label]="('translations.global.ariaLabels.activityIsNot' | transloco)">
      <span class="small">
        {{ 'translations.filter.hasNot' | transloco }}
      </span>
    </button>
  </ng-template>
</mat-menu>
<mat-menu #filterTypeMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="setFilterType('SINCE')"
            tabindex="0"
            class="is-item"
            [attr.aria-label]="('translations.global.ariaLabels.since' | transloco)">
      <span class="small">
        {{ 'translations.filter.activity.since' | transloco }}
      </span>
    </button>
    <button mat-menu-item (click)="setFilterType('FOR_THE_LAST')"
            tabindex="0"
            class="is-item"
            [attr.aria-label]="('translations.global.ariaLabels.forTheLast' | transloco)">
      <span class="small">
        {{ 'translations.filter.activity.forTheLast' | transloco }}
      </span>
    </button>
    <button mat-menu-item (click)="setFilterType('IN_RANGE')"
            tabindex="0"
            class="is-item"
            [attr.aria-label]="('translations.global.ariaLabels.inRange' | transloco)">
      <span class="small">
        {{ 'translations.global.ariaLabels.inRange' | transloco }}
      </span>
    </button>
  </ng-template>
</mat-menu>
