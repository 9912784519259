/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { createLineChartConfig } from '@app/app/page-modules/playlist/components/create/analytics/chart-config.utils';
import { CardEventInfo } from '@app/app/shared/models/analytics/analytics.model';

@Component({
  selector: 'ptl-daily-enrollment-chart',
  templateUrl: './daily-enrollment-chart.component.html',
  styleUrls: ['../common-analytics-chart-styles.scss', './daily-enrollment-chart.component.scss']
})
export class DailyEnrollmentChartComponent implements OnChanges {
  @Input() dailyEnrollmentCounts: Record<string, number>;
  @Input() eventIdsToRegistrationCounts: Record<string, number>;
  @Input() cardEventsInfo: CardEventInfo[];
  @Input() fixedHeight = false;
  @Input() showPlaceholder = false;

  chartConfig: ChartConfiguration<'line'>;
  sortedEventRegistrationData: { eventHeader: string; count: number }[] = [];
  sortOrder: 'asc' | 'desc' = 'desc';
  maxRegistrationCount = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['eventIdsToRegistrationCounts']) {
      this.updateEventRegistrationData();
    }
    if (changes['dailyEnrollmentCounts'] && !this.eventIdsToRegistrationCounts) {
      this.buildChartConfig();
    }
  }

  toggleSortOrder(): void {
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    this.sortEventRegistrationData();
  }

  private updateEventRegistrationData(): void {
    if (!this.eventIdsToRegistrationCounts) {
      this.sortedEventRegistrationData = [];
      return;
    }

    const eventHeadersMap = this.cardEventsInfo.reduce((map, info) => {
      map[info.eventCardId] = info.eventHeader;
      return map;
    }, {} as Record<string, string>);

    this.sortedEventRegistrationData = Object.entries(this.eventIdsToRegistrationCounts).map(([eventId, count]) => ({
      eventHeader: eventHeadersMap[eventId] || eventId,
      count,
    }));

    this.maxRegistrationCount = Math.max(...this.sortedEventRegistrationData.map(data => data.count), 0);
    this.sortEventRegistrationData();
  }

  private sortEventRegistrationData(): void {
    this.sortedEventRegistrationData.sort((a, b) => {
      return this.sortOrder === 'desc' ? b.count - a.count : a.count - b.count;
    });
  }

  private buildChartConfig(): void {
    if (!this.dailyEnrollmentCounts) {
      this.chartConfig = null;
      return;
    }

    const sortedCounts = Object.entries(this.dailyEnrollmentCounts)
    .sort(([dateA], [dateB]) => new Date(dateA).getTime() - new Date(dateB).getTime());

    this.chartConfig = createLineChartConfig('Daily Registrations');
    this.chartConfig.data.labels = sortedCounts.map(([date]) =>
      new Date(date).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })
    );
    this.chartConfig.data.datasets[0].data = sortedCounts.map(([, count]) => count);
  }
}
