/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';
import { GeneratedReportStandardSection } from '../../../../../../shared/models';

@Component({
  selector: 'ptl-diagnostics-report-standard-section',
  templateUrl: './standard-section.component.html',
  styleUrls: ['./standard-section.component.scss'],
})
export class DiagnosticsReportStandardSectionComponent {

  @Input() section: GeneratedReportStandardSection;

  @Input() isFirst: boolean;
}
