<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="video-preview-element
  video-preview-element-{{ content?.type }}
  video-preview-element-position-{{ content?.position || 'CENTER' }}"
  *ngIf="content?.type === 'MEDIA_UPLOAD'"
  [ngClass]="'video-preview-element--' + content?.type">
  <video
    tabindex="0"
    class="video-preview-element--video"
    [src]="content?.content.url"
    controls>
  </video>
  <div class="video-preview-element__caption-box" *ngIf="content?.caption">
    <p
      tabindex="0"
      class="caption-text">
      {{content?.caption}}
    </p>
  </div>
</div>
