export interface TooltipOption {
  image: string;
  title: string;
  description: string;
  type: string | null;
}

export const TooltipOptions: TooltipOption[] = [
	{
		image: '/assets/toolbar/text-box.png',
		title: 'translations.editor.toolbarNew.option.textForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.textForm.tooltipDescription',
		type: 'ParagraphBlockTool'
	},
	{
		image: '/assets/toolbar/link.png',
		title: 'translations.editor.toolbarNew.option.link.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.link.tooltipDescription',
		type: 'ExternalContentBlockTool'
	},
	{
		image: '/assets/toolbar/file.png',
		title: 'translations.editor.toolbarNew.option.file.tooltipTitleDocument',
		description: 'translations.editor.toolbarNew.option.file.tooltipDescription',
		type: 'FileBlockTool'
	},
	{
		image: '/assets/toolbar/video.png',
		title: 'translations.editor.toolbarNew.option.video.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.video.tooltipDescription',
		type: 'StreamVideoBlockTool'
	},
	{
		image: '/assets/toolbar/audio.png',
		title: 'translations.editor.toolbarNew.option.audio.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.audio.tooltipDescription',
		type: 'AudioBlockTool'
	},
	{
		image: '/assets/toolbar/image.png',
		title: 'translations.editor.toolbarNew.option.image.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.image.tooltipDescription',
		type: 'ImageBlockTool'
	},
	{
		image: '/assets/toolbar/code.png',
		title: 'translations.editor.toolbarNew.option.embed.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.embed.tooltipDescription',
		type: 'EmbedCodeBlockTool'
	},
	{
		image: '/assets/toolbar/chart.png',
		title: 'translations.editor.toolbarNew.option.chart.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.chart.tooltipDescription',
		type: null
	},
	{
		image: '/assets/toolbar/divider.png',
		title: 'translations.editor.toolbarNew.option.separator.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.separator.tooltipDescription',
		type: 'SeparatorBlockTool'
	},
	{
		image: '/assets/toolbar/text-box.png',
		title: 'translations.editor.toolbarNew.option.textForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.textForm.tooltipDescription',
		type: 'TextboxFormBlockTool'
	},
	{
		image: '/assets/toolbar/connected-textbox.png',
		title: 'translations.editor.toolbarNew.option.connectedTextForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.connectedTextForm.tooltipDescription',
		type: 'ConnectedTextboxFormBlockTool'
	},
	{
		image: '/assets/toolbar/question-form.png',
		title: 'translations.editor.toolbarNew.option.questionForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.questionForm.tooltipDescription',
		type: 'QuestionFormBlockTool'
	},
	{
		image: '/assets/toolbar/private.png',
		title: 'translations.editor.toolbarNew.option.privateNote.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.privateNote.tooltipDescription',
		type: 'PrivateNoteFormBlockTool'
	},
	{
		image: '/assets/toolbar/check-box.png',
		title: 'translations.editor.toolbarNew.option.checkboxForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.checkboxForm.tooltipDescription',
		type: 'CheckboxFormBlockTool'
	},
	{
		image: '/assets/toolbar/file-form.png',
		title: 'translations.editor.toolbarNew.option.fileForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.fileForm.tooltipDescription',
		type: 'UserFileUploadFormBlockTool'
	},
	{
		image: '/assets/toolbar/booking.png',
		title: 'translations.editor.toolbarNew.option.bookingForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.bookingForm.tooltipDescription',
		type: 'BookingFormBlockTool'
	},
	{
		image: '/assets/toolbar/quiz.png',
		title: 'translations.editor.toolbarNew.option.quizForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.quizForm.tooltipDescription',
		type: 'QuizFormBlockTool'
	},
	{
		image: '/assets/toolbar/log.png',
		title: 'translations.editor.toolbarNew.option.collectorForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.collectorForm.tooltipDescription',
		type: 'CollectorFormBlockTool'
	}
];
