/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from '../../services/translation/translation.service';
import {MediaType} from '@app/app/shared/models';

const MAX_FILE_SIZES_MB = {
  STREAM_VIDEO: 500,
  AUDIO: 200,
  DOCUMENT: 200,
};
const BYTE_MULTIPLIER = 1024 * 1024;
const SUPPORTED_FORMATS = {
  STREAM_VIDEO: 'MP4, MOV, AVI, WebM, M4V',
  AUDIO: 'MP3, WAV, OGG, FLAC, AAC, M4A',
  DOCUMENT: 'PDF, DOC, DOCX, XLS, XLSX, PPT, PPTX, TXT, RTF, ODT, ODS, ODP',
};
const MIME_TYPES = {
  STREAM_VIDEO: [
    'video/mp4',
    'application/mp4',
    'video/quicktime',
    'video/webm',
    'video/x-m4v',
    'video/avi',
  ],
  AUDIO: [
    'audio/mpeg',
    'audio/wav',
    'audio/ogg',
    'audio/flac',
    'audio/aac',
    'audio/m4a',
  ],
  DOCUMENT: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'application/rtf',
  ],
};
const EXTENSIONS = {
  STREAM_VIDEO: ['.mp4', '.mov', '.avi', '.webm', '.m4v'],
  AUDIO: ['.mp3', '.wav', '.ogg', '.flac', '.aac', '.m4a'],
  DOCUMENT: ['.pdf', '.doc', '.docx', '.xls', '.xlsx', 'ppt', 'pptx', '.txt', '.rtf', '.odt', 'ods', 'odp'],
};
@Component({
  selector: 'ptl-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss'],
})
export class FileUploadDialogComponent implements OnInit {

  @ViewChild('uploadFolioInput') uploadFolioInput?: ElementRef;

  filesHovered = false;
  uploadErrorText: string | null = null;
  type: string;
  accept: string;
  maxFileSizeMB = '';
  supportedFormats = '';

  readonly dialogTypeMapping = {
    STREAM_VIDEO: 'translations.editor.toolbar.label.uploadVideo',
    AUDIO: 'translations.editor.toolbar.label.uploadAudio',
    DOCUMENT: 'translations.editor.toolbar.label.uploadFile',
  };
  constructor(
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    private translationService: TranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { type: MediaType }
  ) {
    this.type = this.data.type;
  }

  ngOnInit(): void {
    this.initializeTypeSettings();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  triggerFileSelection(): void {
    this.uploadErrorText = null;
    this.uploadFolioInput?.nativeElement.click();
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this.validateAndUploadFile(input.files[0]);
    }
  }

  onFileDropped(fileList: FileList): void {
    if (fileList.length === 1) {
      this.validateAndUploadFile(fileList.item(0));
    }
  }

  private initializeTypeSettings(): void {
    this.accept = MIME_TYPES[this.type]?.join(', ');
    this.maxFileSizeMB = MAX_FILE_SIZES_MB[this.type];
    this.supportedFormats = SUPPORTED_FORMATS[this.type];
  }

  private validateAndUploadFile(file: File): void {
    this.uploadErrorText = null;
    this.filesHovered = false;
    if (file && this.isValidFile(file)) {
      this.dialogRef.close(file);
    }
  }

  private isValidFile(file: File): boolean {
    const fileExtension = this.getFileExtension(file.name);
    if (!this.isValidMimeType(file) || !this.isValidExtension(fileExtension)) {
      this.uploadErrorText = this.translationService.getTranslation('errors.errorUnsupportedFileType');
      return false;
    }
    if (file.size > MAX_FILE_SIZES_MB[this.type] * BYTE_MULTIPLIER) {
      this.uploadErrorText = this.translationService.getTranslation('errors.errorFileSizeExceedsLimit');
      return false;
    }
    return true;
  }

  private isValidMimeType(file: File): boolean {
    return MIME_TYPES[this.type]?.includes(file.type);
  }

  private isValidExtension(fileExtension: string): boolean {
    return EXTENSIONS[this.type]?.includes(fileExtension);
  }

  private getFileExtension(fileName: string): string {
    return fileName.slice(fileName.lastIndexOf('.')).toLowerCase();
  }
}
