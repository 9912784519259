/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {NgZone} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Store} from '@ngxs/store';
import {tap} from 'rxjs/operators';
import {FileUploadHelper} from '../../../../../../shared/helpers/file-upload-helper';
import {EditorTransformer} from '../../../../../../shared/helpers/editor-transformer';
import {SnackbarHelper} from '../../../../../../shared/helpers/snackbar-helper';
import {MediaUploadData, Resource, ResourceCardType, ResourceSection} from '../../../../../../shared/models';
import {FileUploadService} from '../../../../../../shared/services/file-upload/file-upload.service';
import {SubheaderDataService} from '../../../../../../shared/services/subheader/subheader-data.service';
import {TranslationService} from '../../../../../../shared/services/translation/translation.service';
import {SaveNewCardDataToPlaylistSection} from '../../../../../playlist/store/create/playlist-creation.actions';
import {PlaylistCreationState} from '../../../../../playlist/store/create/playlist-creation.state';
import {ResourceSectionRequest} from '../../../../models/editor';
import {ResourceDataService} from '../../../../services/editor/core/data.service';
import {DynamicContentUploadHelper} from './dynamic-content-upload.helper';
import {ResourceAdminStateModel} from '../../../admin/resource-admin.state.model';

export class SaveHelper {

	static saveUpdates(
		state: ResourceAdminStateModel,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		subheaderService: SubheaderDataService,
		store: Store,
		snackBar: MatSnackBar,
		ngZone: NgZone,
		translationService: TranslationService,
		groupUri: string,
		preventRedirection: boolean,
		languageCode?: string
	) {

		if ( !state.resource ) {
			return;
		}

		const resourceData = state.resource;
		const cardType = state.resource.cardType;
		const resource = {
			...state.resource,
			settings: state.resource.settings ? {
				...state.resource.settings,
				publication: {
					...state.resource.settings.publication
				}
			} : null
		};

		if ( !resourceData.content.headline ) {
			return this.resourceHeadlineEmptyValidationHandler(state, patchState);
		}

		if ( !this.formFieldsAreValid(resourceData) ) {
			return this.resourceFormValidationHandler(state, patchState, snackBar, ngZone, translationService);
		}

		this.setStateInLoadedMode(patchState);

		if ( resourceData.cardType === 'WEBLINK' && resourceData.subHeader?.length ) {
			subheaderService.updateCardSubheader(resourceData._id,
				{
					subHeader: resourceData.subHeader
				}, languageCode).subscribe();
		}

		return this.processResourceUpdateResponse(
			patchState,
			store,
			resource,
			resource.settings?.publication.uri,
			groupUri,
			preventRedirection,
			cardType
		);

	}

	private static processResourceUpdateResponse(
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		store: Store,
		resource: Resource,
		resourceUri: string,
		groupUri: string,
		preventRedirection: boolean,
		cardType: ResourceCardType
	) {
		const parentPlaylistHasNewCard = store.selectSnapshot(PlaylistCreationState.hasNewCard);
		// save card to playlist section if newly created
		if ( preventRedirection ) {
			if ( parentPlaylistHasNewCard ) {
				store.dispatch(new SaveNewCardDataToPlaylistSection(resource));
			}
			this.setStateInLoadedMode(patchState);
			return;
		}
		// save card to playlist section if newly created and go back
		if ( parentPlaylistHasNewCard ) {
			store.dispatch(new SaveNewCardDataToPlaylistSection(resource)).toPromise().then(() => {
				DynamicContentUploadHelper.goBack(patchState, store, resourceUri, groupUri, cardType);
			});
		} else {
			DynamicContentUploadHelper.goBack(patchState, store, resourceUri, groupUri, cardType);
		}
	}

	private static resourceHeadlineEmptyValidationHandler(
		state: ResourceAdminStateModel,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>
	) {
		return patchState({
			contentChanged: false,
			saveStatus: {
				autoSaveInProgress: false
			}
		});
	}

	private static resourceFormValidationHandler(
		state: ResourceAdminStateModel,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		snackBar: MatSnackBar,
		ngZone: NgZone,
		translationService: TranslationService
	) {
		SnackbarHelper.showTranslatableSnackBar(ngZone, snackBar, translationService, 'completeFormData');
		return patchState({
			contentChanged: false,
			saveStatus: {
				autoSaveInProgress: false
			}
		});
	}

	static saveSectionItem(
		snackBar: MatSnackBar,
		ngZone: NgZone,
		translationService: TranslationService,
		sectionUid: string,
		getState: () => ResourceAdminStateModel,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		dataService: ResourceDataService,
		resourceData: Resource,
		fileUploadService: FileUploadService,
		languageCode: string
	) {
		const resource = resourceData;
		if ( !this.formFieldsAreValid(resource) ) {
			this.setStateInLoadedMode(patchState);
			SnackbarHelper.showTranslatableSnackBar(ngZone, snackBar, translationService, 'completeFormData');
			return undefined;
		}

		const resourceUid = resource._id;
		const sectionToSave = resource.content.sections.find(section => section.uid === sectionUid);

		if ( !sectionToSave ) {
			this.setStateInLoadedMode(patchState);
			SnackbarHelper.showTranslatableSnackBar(ngZone, snackBar, translationService, 'noSectionFoundToSave');
			return undefined;
		}

		const sectionData = {
			...sectionToSave,
			dynamicContent: sectionToSave.dynamicContent
			.map(item => EditorTransformer.transformEditorContentToMatchApiExpectations(item)),
			languageCode: languageCode
		} as ResourceSectionRequest;

		return dataService.updateSection(resourceUid,
			sectionUid,
			sectionData,
			languageCode).pipe(
			tap(( { isSuccess, value } ) => {
				if ( isSuccess ) {
					const sectionFromResponse = value.item;
					const hasFile =
            		sectionToSave.dynamicContent.find(content => content.type === 'MEDIA_UPLOAD' && !!(content as MediaUploadData).file);
          			if (hasFile) {
            			FileUploadHelper.uploadObservable(sectionToSave, fileUploadService, value.uploadUrl)
              			.subscribe(() => {
                			this.patchStateWithUpdatedSection(sectionFromResponse, patchState, getState(), sectionUid);
              			});
          			} else {
            			this.patchStateWithUpdatedSection(sectionFromResponse, patchState, getState(), sectionUid);
          			}
				}
			})
		);
	}

	private static patchStateWithUpdatedSection(
		newSection: ResourceSection,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		state: ResourceAdminStateModel,
		sectionUid: string
	) {
		patchState({
			resource: {
				...state.resource,
				content: {
					...state.resource.content,
					sections: state.resource.content.sections.map(section =>
						this.getUpdatedSections(section, newSection, sectionUid))
				}
			},
			contentChanged: false,
			saveStatus: {
				autoSaveInProgress: false
			}
		});
		// setTimeout(() => {
		//   const el = document.querySelector('[data-medium-focused="true"]');
		//   if (el) {
		//     this.updateCursorPosition(el as HTMLElement);
		//   }
		// }, 0);

	}

	private static getUpdatedSections( section: ResourceSection, newSection: ResourceSection, sectionUid: string ) {
		if ( section.uid === sectionUid ) {
			const editorElements = document.getElementsByClassName('f_editor-element');
			for ( let i = 0; i < newSection.dynamicContent.length; i++ ) {
				const content = newSection.dynamicContent[i];
				if ( content.type === 'MEDIA_UPLOAD' ) {
					if ( section.dynamicContent[i].index !== content.index ) {
						section.dynamicContent[i] = content;
					}
					if ( section.dynamicContent[i].index === undefined && content.index === undefined ) {
						section.dynamicContent[i] = content;
					}
				} else if ( content.type === 'FORM' ) {
					if ( section.dynamicContent[i].index !== content.index ) {
						section.dynamicContent[i] = content;
					}
				} else if ( content.type === 'PARAGRAPH' ) {
					if ( editorElements.length && editorElements[i] ) {
						const editableContent = editorElements[i].querySelector('.me-editable');
						if ( editableContent ) {
							section.dynamicContent[i].content = editableContent.innerHTML;
						}
					}
					section.dynamicContent[i].uid = content.uid;
				} else if ( content.type === 'TABLE' ) {
					section.dynamicContent[i].uid = content.uid;
				} else {
					section.dynamicContent[i] = content;
				}
			}
		}
		return section;
	}

	private static formFieldsAreValid( resourceContent: Resource ): boolean {
		const someFormFieldIsInvalid = resourceContent.content.sections.some(section =>
			section.dynamicContent
			.filter(content => content.type === 'FORM')
			.some(data => !data['content'] ||
				(data['content'].type !== 'COLLECTOR' && !data['content'].title) ||
				(data['content'].type === 'TEXTBOX' && !data['content'].wordLimit))
		);
		return !someFormFieldIsInvalid;
	}

	private static setStateInLoadedMode(
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel> ) {
		patchState({
			contentChanged: false,
			saveStatus: {
				autoSaveInProgress: false
			}
		});
	}

	private static updateCursorPosition( element: HTMLElement ) {
		const offset = this.getCharacterOffsetWithin(element);
		const isHighlighted = this.getSelectedText();
		if ( !isHighlighted ) {
			this.setCurrentCursorPosition(offset, element);
		}
	}

	private static getSelectedText() {
		let text = '';
		if ( typeof window.getSelection !== 'undefined' ) {
			text = window.getSelection().toString();
			// @ts-ignore
		} else if ( typeof document.selection !== 'undefined' && document.selection.type === 'Text' ) {
			// @ts-ignore
			text = document.selection.createRange().text;
		}
		return text;
	}


	private static getCharacterOffsetWithin( element ) {
		let position = 0;
		const isSupported = typeof window.getSelection !== 'undefined';
		if ( isSupported ) {
			const selection = window.getSelection();
			if ( selection.rangeCount !== 0 ) {
				const range = window.getSelection().getRangeAt(0);
				const preCaretRange = range.cloneRange();
				preCaretRange.selectNodeContents(element);
				preCaretRange.setEnd(range.endContainer, range.endOffset);
				position = preCaretRange.toString().length;
			}
		}
		return position;
	}

	private static setCurrentCursorPosition( chars, el ) {
		if ( chars > 0 ) {
			const selection = window.getSelection();

			const range = this.createRange(el.parentNode, { count: chars });

			if ( range ) {
				range.collapse(false);
				selection.removeAllRanges();
				selection.addRange(range);
			}
		}
	}

	private static createRange( node, chars, range? ) {
		if ( !range ) {
			range = document.createRange();
			range.selectNode(node);
			range.setStart(node, 0);
		}

		if ( chars.count === 0 ) {
			range.setEnd(node, chars.count);
		} else if ( node && chars.count > 0 ) {
			if ( node.nodeType === Node.TEXT_NODE ) {
				if ( node.textContent.length < chars.count ) {
					chars.count -= node.textContent.length;
				} else {
					range.setEnd(node, chars.count);
					chars.count = 0;
				}
			} else {
				/* eslint-disable-next-line @typescript-eslint/prefer-for-of */
				for ( let lp = 0; lp < node.childNodes.length; lp++ ) {
					range = this.createRange(node.childNodes[lp], chars, range);

					if ( chars.count === 0 ) {
						break;
					}
				}
			}
		}
		return range;
	}
}
