import {Component, Input, OnInit} from '@angular/core';
import {
  TooltipOption,
  TooltipOptions
} from '@app/app/shared/editor-js/components/toolbar-tooltip/TooltipOptions';

@Component({
  selector: 'ptl-toolbar-tooltip',
  templateUrl: './toolbar-tooltip.component.html',
  styleUrls: ['./toolbar-tooltip.component.scss']
})
export class ToolbarTooltipComponent implements OnInit {

  @Input() type: string;
  @Input() topPosition: number;
  @Input() leftPosition: number;

  tooltipData: TooltipOption;

  ngOnInit() {
    TooltipOptions.forEach((el: TooltipOption) => {
      if (el.type === this.type) {
        this.tooltipData = el;
      }
    });
  }
}
