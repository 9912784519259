/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ptl-most-completions-chart',
  templateUrl: './most-completions-chart.component.html',
  styleUrls: ['../common-analytics-chart-styles.scss', './most-completions-chart.component.scss'],
})
export class MostCompletionsChartComponent implements OnChanges {
  @Input() cardCompletions: Record<string, number>;
  @Input() totalMembers: number;

  completionsData: { label: string; count: number; percentage: string }[] = [];
  maxCompletion = 0;
  sortOrder: 'asc' | 'desc' = 'desc';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cardCompletions'] || changes['totalMembers']) {
      this.buildCompletionsData();
    }
  }

  private buildCompletionsData(): void {
    if (!this.cardCompletions || this.totalMembers <= 0) {
      this.completionsData = [];
      this.maxCompletion = 0;
      return;
    }

    const completions = Object.entries(this.cardCompletions)
    .map(([label, count]) => ({
      label,
      count,
      percentage: ((count * 100) / this.totalMembers).toFixed(1) + '%',
    }));

    this.completionsData = completions.sort((a, b) =>
      this.sortOrder === 'desc' ? b.count - a.count : a.count - b.count
    );

    this.maxCompletion = Math.max(...completions.map(item => item.count), 0);
  }

  toggleSortOrder(): void {
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    this.buildCompletionsData();
  }
}
