/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { catchError, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { PlaylistDataService } from './data.service';
import {
  AssociateCategoryRequest,
  AssociateTagRequest,
  PlaylistCardLayoutUpdate,
  PlaylistCardOrderingUpdate,
  PlaylistCreationRequest,
  PlaylistPublicationStuffUpdateRequest,
  PlaylistThumbnailUpdateRequest,
  PlaylistUpdateRequest,
  PlaylistWebLinkCreationRequest
} from '../../../models';
import { RestClientService } from '../../../../../shared/services/rest-client.service';
import { ObservableResult } from '../../../../../shared/store';
import { PlaylistResourceModel } from '../../../models/playlist-resource.model';
import { environment } from '../../../../../../environments/environment';
import {
  Diagnostics, EnrollmentSettingsRequest, EventTicketsDetails,
  Playlist,
  PlaylistAssociateAdmin, PlaylistStandardSection,
  Resource,
  ResourceCardType,
  ResourcePermission
} from '../../../../../shared/models';
import { CreatePlaylistResponse } from './models/create-playlist-response';
import { UpdatePlaylistResponse } from './models/update-playlist-response';
import { ItemWithUploadUrl } from '../../../../../shared/models/ItemWithUploadUrl';
import { Page } from '../../../../../shared/models/page';
import { LearnerPlaylistSummary } from '../../../../../shared/models/playlist/learner-playlist-summary.model';
import {
  DEFAULT_LANGUAGE_CODE,
  TranslationService
} from '../../../../../shared/services/translation/translation.service';
import { ContentStorePlaylistSummary } from '../../../../../shared/models/content-store/content-store.model';
import { LanguageCodeHelper } from '../../../../../shared/helpers/language-code-helper';
import {
  PlaylistMemberMetrics,
  PlaylistsStatsSummary,
  PlaylistStatistics,
  StatsData
} from '../../../../../shared/models/analytics/analytics.model';
import { HttpErrorResponse } from '@angular/common/http';
import { EventTicketsRequest } from '@app/app/page-modules/resource/store/admin/resource-event-admin.state.model';
import { MembersBooleanSearchRequest } from '@app/app/shared/models/admin/boolean-filters.model';

@Injectable()
export class ApiPlaylistDataService implements PlaylistDataService {

  constructor(private client: RestClientService, private translationService: TranslationService) {
  }

  createPlaylist(request: PlaylistCreationRequest): ObservableResult<CreatePlaylistResponse> {
    return this.client.post<CreatePlaylistResponse>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'playlists'),
      request,
      undefined,
      request.languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(request.languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(err))
    );
  }

  updatePlaylist(playlistUid: string, request: PlaylistUpdateRequest, languageCode?: string): ObservableResult<UpdatePlaylistResponse> {
    return this.client.patch<UpdatePlaylistResponse>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(err))
    );
  }

  getPlaylistSummaryByUid(playlistUid: string, languageCode?: string): ObservableResult<ContentStorePlaylistSummary> {
    return this.client.get<ContentStorePlaylistSummary>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/store/summary`),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetPlaylist')))
    );
  }

  deletePlaylist(playlistUid: string): ObservableResult<void> {
    return this.client.delete(Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}`)).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorDeletePlaylist')))
    );
  }

  getPlaylistDetails(playlistUri: string, publisherUri: string, packageUri: string, pageUri: string, languageCode?: string):
    ObservableResult<Playlist> {

    let url = `playlists?uri=${playlistUri}`;

    if (pageUri && (!packageUri && !publisherUri)) {
      url = `playlists?uri=${playlistUri}&in=${pageUri}`
    }
    if (packageUri && publisherUri) {
      url = `pub/${publisherUri}/${packageUri}`;
      if (pageUri) {
        url += `/pages/${pageUri}`;
      }
      url += `/playlists/${playlistUri}`
    }
    return this.client.get<Playlist>(
      Location.joinWithSlash(environment.apiRootUrl || '', url),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) =>
        this.handleErrorResponseGetPlaylist(err, this.translationService.getTranslation('errors.errorGetPlaylist'))
      ));
  }


  clonePlaylist(playlistUid: string): ObservableResult<string> {
    return this.client.post<string>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/clone`),
      {},
      undefined,
      {},
      'text'
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorClonePlaylist')))
    );
  }

  associateTag(playlistUid: string, request: AssociateTagRequest): ObservableResult<PlaylistResourceModel> {
    return this.client.post<PlaylistResourceModel>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/tags`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddTag')))
    );
  }

  dissociateTag(playlistUid: string, tagUid: string): ObservableResult<void> {
    return this.client.delete(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/tags/${tagUid}`)
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveTag')))
    );
  }


  associateFeedTag(playlistUid: string, request: AssociateTagRequest): ObservableResult<PlaylistResourceModel> {
    return this.client.post<PlaylistResourceModel>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/feeds/tags`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddTag')))
    );
  }

  dissociateFeedTag(playlistUid: string, tagUid: string): ObservableResult<void> {
    return this.client.delete(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/feeds/tags/${tagUid}`)
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveTag')))
    );
  }


  dissociateAllFeedTags(playlistUid: string): ObservableResult<void> {
    return this.client.delete(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/feeds/tags`)
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveTags')))
    );
  }

  associateCategory(playlistUid: string, request: AssociateCategoryRequest): ObservableResult<PlaylistResourceModel> {
    return this.client.post<PlaylistResourceModel>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/categories`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddPlaylistCategory')))
    );
  }

  dissociateCategory(playlistUid: string, categoryTagUid: string): ObservableResult<void> {
    return this.client.delete(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/categories/${categoryTagUid}`)
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemovePlaylistCategory')))
    );
  }

  associateAdmin(playlistUid: string, userUid: string): ObservableResult<PlaylistAssociateAdmin[]> {
    const request = { adminUid: userUid };
    return this.client.post<PlaylistAssociateAdmin[]>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/collaborations`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddAdmin')))
    );
  }

  dissociateAdmin(playlistUid: string, userUid: string): ObservableResult<void> {
    return this.client.delete(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/collaborations/${userUid}`)
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveAdmin')))
    );
  }

  enablePlaylistCollaboration(playlistUid: string): ObservableResult<void> {
    return this.client.patch(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/collaborations/enable`),
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorEnablePlaylistCollaboration')))
    );
  }

  disablePlaylistCollaboration(playlistUid: string): ObservableResult<void> {
    return this.client.patch(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/collaborations/disable`),
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorDisablePlaylistCollaboration')))
    );
  }

  enablePlaylistMemberCollaboration(playlistUid: string, userUid: string): ObservableResult<void> {
    return this.client.patch(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/collaborations/${userUid}/enable`),
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorEnablePlaylistMemberCollaboration')))
    );
  }

  disablePlaylistMemberCollaboration(playlistUid: string, userUid: string): ObservableResult<void> {
    return this.client.patch(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/collaborations/${userUid}/disable`),
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorDisablePlaylistMemberCollaboration')))
    );
  }


  changeAdminPermissions(playlistUid: string, userUid: string, permissions: ResourcePermission): ObservableResult<void> {
    return this.client.patch(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/collaborations/${userUid}/permissions`),
      permissions
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateAdminPermission')))
    );
  }

  getPlaylistStatistics(playlistUid: string, from: string, to: string, daysDiff: number): ObservableResult<PlaylistStatistics> {
    const params = {
      from: from,
      to: to,
    }
    if (daysDiff) {
      params['diffLastXDays'] = daysDiff.toString()
    }
    return this.client.get<PlaylistStatistics>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/analytics/members/stats/playlists/${playlistUid}/report`),
      params,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGettingPlaylistStatistics')))
    );
  }

  getPlaylistMetrics(playlistUid: string, request: MembersBooleanSearchRequest): ObservableResult<PlaylistMemberMetrics> {
    return this.client.post<PlaylistMemberMetrics>(Location.joinWithSlash(
        environment.apiRootUrl || '', `analytics/metrics/playlists/${playlistUid}`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGettingPlaylistStatistics')))
    );
  }

  getPlaylistStartedStatistics(playlistUid: string, from: string, to: string): ObservableResult<StatsData[]> {
    return this.client.get<StatsData[]>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/analytics/members/stats/playlists/${playlistUid}/started`),
      {
        from: from,
        to: to,
      },
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGettingPlaylistStartedStatistics')))
    );
  }

  getPlaylistCompletedStatistics(playlistUid: string, from: string, to: string): ObservableResult<StatsData[]> {
    return this.client.get<StatsData[]>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/analytics/members/stats/playlists/${playlistUid}/completed`),
      {
        from: from,
        to: to,
      },
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGettingPlaylistCompletedStatistics')))
    );
  }

  getPlaylistStatisticsSummary(playlistUid: string): ObservableResult<PlaylistsStatsSummary> {
    return this.client.get<PlaylistsStatsSummary>(Location.joinWithSlash(environment.apiRootUrl || '',
      `analytics/members/stats/playlists/${playlistUid}/summary`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetStatisticsSummary'))));
  }


  updatePlaylistUri(
    playlistUid: string,
    data: PlaylistPublicationStuffUpdateRequest
  ): ObservableResult<void> {
    return this.client.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/publication`),
      data,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorPlaylistPublicationData')))
    );
  }

  changeCardsLayout(
    playlistUid: string,
    sectionUid: string,
    request: PlaylistCardLayoutUpdate
  ): ObservableResult<void> {
    return this.client.patch<void>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `playlists/${playlistUid}/sections/${sectionUid}/cards-layout`
      ),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateCardLayout')))
    );
  }

  addCardToPlaylist(
    playlistUid: string,
    sectionUid: string,
    cardUid: string,
    clone = false
  ): ObservableResult<string> {
    return this.client.put<string>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `playlists/${playlistUid}/sections/${sectionUid}/cards/${cardUid}${clone ? '/clone' : ''}`
      ),
      cardUid,
      {},
      {},
      clone ? 'text' : undefined
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCardExistsInSection')))
    );
  }

  addWebLinkToPlaylistSection(
    playlistUid: string,
    sectionUid: string,
    requestBody: PlaylistWebLinkCreationRequest
  ): ObservableResult<Resource> {
    return this.client.put<Resource>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `playlists/${playlistUid}/sections/${sectionUid}/weblinks`
      ),
      requestBody
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateWeblink')))
    );
  }

  updatePlaylistSectionCards(
    playlistUid: string,
    sectionUid: string,
    cardsArray: PlaylistCardOrderingUpdate
  ) {
    return this.client.patch<void>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `playlists/${playlistUid}/sections/${sectionUid}/cards`
      ),
      cardsArray
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCardExistsInSection')))
    );
  }

  movePlaylistCardFromSections(playlistUid: string, fromSectionUid: string, toSectionUid, cardUid: string) {
    return this.client.patch<void>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `playlists/${playlistUid}/cards/${cardUid}/moves?from=${fromSectionUid}&to=${toSectionUid}`
      ),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCardExistsInSection')))
    );
  }

  removeCardFromSection(
    playlistUid: string,
    sectionUid: string,
    uid: string
  ) {
    return this.client.delete<void>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `playlists/${playlistUid}/sections/${sectionUid}/cards/${uid}`
      )
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorDeleteCard')))
    );
  }

  createEmptyCard(playlistUid: string, sectionUid: string): ObservableResult<Resource> {
    return this.client.post<Resource>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/sections/${sectionUid}?cardType=RESOURCE`),
      null,
      {},
      { 'Content-Language': DEFAULT_LANGUAGE_CODE })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateResource')))
      );
  }

  createEmptyEventCard(playlistUid: string, sectionUid: string): ObservableResult<Resource> {
    return this.client.post<Resource>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/sections/${sectionUid}?cardType=EVENT`),
      null,
      {},
      { 'Content-Language': DEFAULT_LANGUAGE_CODE })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateEventCard')))
      );
  }

  createEmptyAssessment(playlistUid: string, sectionUid: string): ObservableResult<Diagnostics> {
    return this.client.post<Diagnostics>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/sections/${sectionUid}?cardType=ASSESSMENT`),
      null,
      {},
      { 'Content-Language': DEFAULT_LANGUAGE_CODE })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateAssessment')))
      );
  }

  createEmptyResourceInGroup(playlistUid: string, sectionUid: string, groupUid: string, type: ResourceCardType)
    : ObservableResult<Resource | Diagnostics> {
    const params = {
      cardType: type,
      groupId: groupUid,
    };
    return this.client.post<Resource>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/sections/${sectionUid}`),
      null,
      params,
      { 'Content-Language': DEFAULT_LANGUAGE_CODE })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(
            this.translationService.getTranslation(
              type === 'RESOURCE' ? 'errors.errorCreateResource' :
                type === 'EVENT' ? 'errors.errorCreateEventCard' : 'errors.errorCreateAssessment'
            )
          )
        )
      );
  }

  createWeblinkInGroupCard(
    playlistUid: string,
    sectionUid: string,
    groupUid: string,
    requestBody: PlaylistWebLinkCreationRequest
  ): ObservableResult<Resource> {
    const params = {
      groupId: groupUid,
    };
    return this.client.put<Resource>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `playlists/${playlistUid}/sections/${sectionUid}/weblinks`
      ),
      requestBody,
      params
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateWeblink')))
    );
  }


  createPlaylistSection(
    playlistUid: string
  ): ObservableResult<PlaylistStandardSection> {
    return this.client.post<PlaylistStandardSection>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/sections`),
      {},
      {},
      { 'Content-Language': DEFAULT_LANGUAGE_CODE }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateSection')))
    );
  }

  deletePlaylistSection(
    playlistUid: string,
    sectionUid: string,
    cards: boolean = true,
  ) {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/sections/${sectionUid}?cards=${cards}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemovingSection')))
    );
  }

  addPlaylistToPlaylist(playlistUid: string, sectionUid: string, cardUid: string, clone = false): ObservableResult<void> {
    return this.client.put<void>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `playlists/${playlistUid}/sections/${sectionUid}/playlists/${cardUid}${clone ? '/clone' : ''}`)
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddPlaylistToPlaylist')))
    );
  }

  addPlaylistToPlaylistMainSection(playlistUid: string, cardUid: string) {
    return this.client.put<void>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `playlists/${playlistUid}/sections/cards/${cardUid}`)
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError((err) => ObservableResult.ofError(err.error?.message))
    );
  }


  reorderPlaylistSections(playlistUid: string, request: { sections: string[] }): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/sections/reorder`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorReorderPlaylistSections')))
    );
  }

  addUserGroupToPlaylistTarget(playlistUid: string, userGroupUid: string): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/target/groups/${userGroupUid}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(err))
    );
  }

  updateUserGroupToPlaylistTargetForceStatus(playlistUid: string, userGroupUid: string, force: boolean):
    ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `playlists/${playlistUid}/target/groups/${userGroupUid}/subscriptions?forceSubscribe=${force}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddUserGroupToPlaylist')))
    );
  }

  removeUserGroupFromPlaylistTarget(playlistUid: string, userGroupUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/target/groups/${userGroupUid}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveUserGroupFromPlaylist')))
    );
  }

  removeAllUserGroupsFromPlaylistTarget(playlistUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/target/groups`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveUserGroupFromPlaylist')))
    );
  }

  addUserToPlaylistTarget(playlistUid: string, userUid: string): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/target/users/${userUid}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(err))
    );
  }

  removeUserFromPlaylistTarget(playlistUid: string, userUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/target/users/${userUid}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveUserFromPlaylist')))
    );
  }

  removeAllUsersFromPlaylistTarget(playlistUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/target/users`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveAllUserFromPlaylist')))
    );
  }

  addSectorToPlaylistTarget(playlistUid: string, sectorCode: string): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/playlists/${playlistUid}/target/sectors/${sectorCode}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(err))
    )
  }

  removeSectorFromPlaylistTarget(playlistUid: string, sectorCode: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/playlists/${playlistUid}/target/sectors/${sectorCode}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(err))
    )
  }

  removeAllSectorsFromPlaylistTarget(playlistUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/playlists/${playlistUid}/target/sectors`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(err))
    )
  }

  changePlaylistThumbnail(
    playlistUid: string,
    request: PlaylistThumbnailUpdateRequest
  ): ObservableResult<ItemWithUploadUrl<PlaylistResourceModel>> {
    return this.client.post<ItemWithUploadUrl<PlaylistResourceModel>>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/thumbnails`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatePlaylistThumbnail')))
    );
  }

  removePlaylistThumbnail(playlistUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/thumbnails`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemovePlaylistThumbnail')))
    );
  }


  setPlaylistTimeRequired(playlistUid: string, timeRequired: number): ObservableResult<void> {
    return this.client.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/time/required`),
      { timeRequired: timeRequired },
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingTimeRequired'))));
  }

  getPlaylistTimeRequiredSuggestion(playlistUid: string, languageCode?: string): ObservableResult<number> {
    return this.client.get<number>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/time/required/suggestion`),
      {},
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGettingTimeRequiredSuggestions'))));
  }

  getArchivedPlaylists(page: number, size: number, term?: string): ObservableResult<Page<LearnerPlaylistSummary>> {
    const params = {
      page: page.toString(),
      size: size ? size.toString() : '10',
    };
    if (term) {
      params['term'] = term;
    }
    return this.client.get<Page<LearnerPlaylistSummary>>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'playlists/archives'),
      params
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetArchivedPlaylist'))));
  }

  archivePlaylist(playlistUid: string): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/archives`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorArchivePlaylist'))));
  }

  addLanguage(playlistUid: string, languageCode: string): ObservableResult<void> {
    return this.client.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/languages/${languageCode}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingLanguage'))));
  }

  removeLanguage(playlistUid: string, languageCode: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/languages/${languageCode}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingLanguage'))));
  }

  enableLanguage(playlistUid: string, languageCode: string): ObservableResult<void> {
    return this.client.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/languages/${languageCode}/enable`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingLanguage'))));
  }

  disableLanguage(playlistUid: string, languageCode: string): ObservableResult<void> {
    return this.client.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/languages/${languageCode}/disable`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingLanguage'))));
  }

  addDataForm(pageUid: string, dataFormUid: string, event: string): ObservableResult<void> {
    const request = {
      dataFormUid: dataFormUid,
      event: event
    };
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${pageUid}/data-forms`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('global.dataForms.error.addingDataForm'))));
  }

  removeDataForm(pageUid: string, dataFormUid: string, event: string): ObservableResult<void> {
    const request = {
      dataFormUid: dataFormUid,
      event: event
    };
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${pageUid}/data-forms`),
      null,
      null,
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('global.dataForms.error.removingDataForm'))));
  }

  private handleErrorResponseGetPlaylist(err: HttpErrorResponse, message: string) {
    const errorMessage = err.status === 404 ? this.translationService.getTranslation('errors.errorPlaylistNotFound') :
      err.status === 403 ? this.translationService.getTranslation('errors.errorPlaylistNotAvailable') : message;
    return ObservableResult.ofError(errorMessage, err.error?.dataForms);
  }

  updateEnrollmentSettings(playlistUid: string, request: EnrollmentSettingsRequest): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/enrollments`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('global.dataForms.error.addingDataForm'))));
  }

  updateTickets(playlistUid: string, request: EventTicketsRequest): ObservableResult<EventTicketsDetails> {
    return this.client.put<EventTicketsDetails>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `playlists/${playlistUid}/tickets`
      ),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(
        this.translationService.getTranslation('errors.errorUpdatingEnrollments')))
    );
  }

  deleteEnrollmentSettings(playlistUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/enrollments`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('global.dataForms.error.addingDataForm'))));
  }

  register(playlistUid: string): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/tickets/register`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(
        this.translationService.getTranslation('errors.errorUpdatingEnrollments'))
      )
    );
  }

  unregister(playlistUid: string): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/tickets/unregister`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(
        this.translationService.getTranslation('errors.errorUpdatingEnrollments')))
    );
  }

  registerUserToPlaylist(playlistUid: string, userUid: string): ObservableResult<Resource> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '', `playlists/${playlistUid}/tickets/register/${userUid}`
    );
    return this.client.post<Resource>(url, {})
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRegisterToEvent')))
      );
  }

  unregisterUserToPlaylist(playlistUid: string, userUid: string): ObservableResult<Resource> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '', `playlists/${playlistUid}/tickets/unregister/${userUid}`
    );
    return this.client.post<Resource>(url, {})
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRegisterToEvent')))
      );
  }

}
