import { ReviewEventType } from '../review/review.model';
import { DistanceTravelRequest, MembersSearchOrder } from './members.model';
import { UserGroups } from '@app/app/shared/models/admin/group/user-groups.model';

export enum BooleanQueryType {
  AND = 'AND',
  OR = 'OR',
  NOT = 'NOT',
  USERS = 'USERS',
  REVIEWERS = 'REVIEWERS',
  ROLES = 'ROLES',
  GROUPS = 'GROUPS',
  ACTIVITY = 'ACTIVITY',
  CARD_STATUS = 'CARD_STATUS',
  PLAYLIST_STATUS = 'PLAYLIST_STATUS',
  FORM_STATUS = 'FORM_STATUS',
  RESOURCES_STATUS = 'RESOURCES_STATUS',
  REVIEW_STATUS = 'REVIEW_STATUS',
  REVIEW_SUBMISSION_DATE = 'REVIEW_SUBMISSION_DATE',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  EXTERNAL_ID = 'EXTERNAL_ID',
  EMAIL = 'EMAIL',
  ATTENDANCE = 'ATTENDANCE'
}

export interface BooleanQueryRequest {
  type: BooleanQueryType;
}

export interface AndRequest extends BooleanQueryRequest {
  type: BooleanQueryType.AND;
  args: BooleanQueryRequest[];
}

export interface OrRequest extends BooleanQueryRequest {
  type: BooleanQueryType.OR;
  args: BooleanQueryRequest[];
}

export interface NotRequest extends BooleanQueryRequest {
  type: BooleanQueryType.NOT;
  arg: BooleanQueryRequest;
}

export interface ActivityRequest extends BooleanQueryRequest {
  type: BooleanQueryType.ACTIVITY;
  operator: TimeOperator;
  time: string;
  startDate?: string;
  endDate?: string;

  // frontend-specific
  frontendType?: 'SINCE' | 'FOR_THE_LAST' | 'IN_RANGE';
  periodType?: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';
  quantity?: number;
}

export interface UsersRequest extends BooleanQueryRequest {
  type: BooleanQueryType.USERS;
  users: string[];
}

export interface ReviewersRequest extends BooleanQueryRequest {
  type: BooleanQueryType.REVIEWERS;
  reviewers: string[];
}

export interface GroupsRequest extends BooleanQueryRequest {
  type: BooleanQueryType.GROUPS;
  groups: string[];
}

export interface RolesRequest extends BooleanQueryRequest {
  type: BooleanQueryType.ROLES;
  roles: string[];
}

export interface PlaylistStatusRequest extends BooleanQueryRequest {
  type: BooleanQueryType.PLAYLIST_STATUS;
  uid: string;
  status: CompletionStatus;
}

export interface CardStatusRequest extends BooleanQueryRequest {
  type: BooleanQueryType.CARD_STATUS;
  uid: string;
  status: CompletionStatus;
}

export interface FormStatusRequest extends BooleanQueryRequest {
  type: BooleanQueryType.FORM_STATUS;
  uid: string;
  status: CompletionStatus;
}

export interface ResourcesStatusRequest extends BooleanQueryRequest {
  type: BooleanQueryType.RESOURCES_STATUS;
  resourcesType: 'CARDS' | 'PLAYLISTS' | 'FORMS';
  operator: 'AT_LEAST' | 'AT_MOST';
  amount: number;
  status: CompletionStatus;
  required: boolean;
}

export interface ReviewStatusRequest extends BooleanQueryRequest {
  type: BooleanQueryType.REVIEW_STATUS;
  status: ReviewEventType;
}

export interface ReviewSubmissionDateRequest extends BooleanQueryRequest {
  type: BooleanQueryType.REVIEW_SUBMISSION_DATE;
  before?: Date;
  after?: Date;
}

export interface FirstNameRequest extends BooleanQueryRequest {
  type: BooleanQueryType.FIRST_NAME;
  firstName: string;
}

export interface LastNameRequest extends BooleanQueryRequest {
  type: BooleanQueryType.LAST_NAME;
  lastName: string;
}

export interface ExternalIdRequest extends BooleanQueryRequest {
  type: BooleanQueryType.EXTERNAL_ID;
  externalId: string;
}

export interface EmailRequest extends BooleanQueryRequest {
  type: BooleanQueryType.EMAIL;
  email: string;
}

export interface AttendanceRequest extends BooleanQueryRequest {
  type: BooleanQueryType.ATTENDANCE;
  attendanceType: AttendanceType;
}

export type AttendanceType = 'REGISTERED' | 'PARTICIPATED' | 'WAITING_LIST';

export interface MembersBooleanSearchRequest {
  query: BooleanQueryRequest;
  order?: MembersSearchOrder;
  distanceTravelled?: DistanceTravelRequest;
}

export interface ReviewsSearchRequest {
  query: BooleanQueryRequest;
  order?: MembersSearchOrder;
}

export type TimeOperator = 'BEFORE' | 'AFTER';
export type CompletionStatus = 'COMPLETED' | 'STARTED';

export type BooleanFilterType =
  'GROUP'
  | 'REVIEW_STATUS'
  | 'REVIEW_SUBMISSION_DATE'
  | 'ROLE'
  | 'ATTENDANCE'
  | 'ACTIVITY'
  | 'COMPLETION';

export interface BooleanFilter {
  request: BooleanQueryRequest | BooleanQueryRequest[];
  message: string;
  type?: BooleanFilterType;
  activeUserGroups?: UserGroups[];
}

export type CompletionType = 'COMPLETED' | 'NOT_COMPLETED' | 'STARTED' | 'NOT_STARTED';
export type CompletionFilterType = 'EVERYTHING' | 'CERTAIN_ACTIVITIES' | 'AT_LEAST' | 'AT_MOST';

export interface CompletionBooleanFilter extends BooleanFilter {
  type: 'COMPLETION';
  resourceCount: number;
  resourcesUids?: string[];
  completionType?: CompletionType;
  completionFilterType?: CompletionFilterType;
}
