<!--
  ~ Copyright (C) 2025 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div *ngIf="courseEngagementData.length > 0; else noDataTemplate" class="chart-dashboard">
  <div class="header-row">
    <p class="chart-dashboard-title">{{ 'translations.analytics.charts.courseEngagements.title' | transloco }}</p>
    <button class="btn-sort"
            (click)="toggleSortOrder()"
            [attr.aria-label]="('translations.global.ariaLabels.toggleSorting' | transloco)">
      <mat-icon>{{ sortOrder === 'desc' ? 'arrow_downward' : 'arrow_upward' }}</mat-icon>
    </button>
  </div>

  <div class="chart-wrapper" [ngClass]="{'fixed-height': fixedHeight}">
    <div class="course-engagements">
      <div *ngFor="let data of courseEngagementData" class="chart-bar">
        <div class="chart-label" matTooltip="{{ data.header }}">{{ data.header }}</div>
        <div class="chart-bar-wrapper" matTooltip="{{ data.header }}">
          <div class="chart-bar-fill" [style.width.%]="(data.totalEngagements / maxTotalEngagements) * 100"></div>
        </div>
        <div class="chart-bar-value">{{ data.totalEngagements }}</div>
      </div>
    </div>
  </div>
</div>

<ng-template #noDataTemplate>
  <div class="placeholder-container" *ngIf="showPlaceholder" [ngClass]="{'fixed-height': fixedHeight}">
    <mat-icon class="placeholder-icon">search_off</mat-icon>
    <p class="placeholder-message">
      {{ 'translations.analytics.charts.courseEngagements.noCoursesFound' | transloco }}
    </p>
    <p class="placeholder-description">
      {{ 'translations.analytics.charts.courseEngagements.noCoursesFoundMessage' | transloco }}
    </p>
  </div>
</ng-template>
