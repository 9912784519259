/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Form, TextBoxFormContent } from '../../../../models';
import { Observable, takeUntil } from 'rxjs';
import { Select } from '@ngxs/store';
import { ResourceAdminState } from '@app/app/page-modules/resource/store/admin/resource-admin.state';

@Component({
	selector: 'ptl-form-private-note',
	templateUrl: './form-private-note.component.html',
	styleUrls: ['../form.component.scss']
})
export class FormPrivateNoteComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

	/** Receives the input data object */
	private _form: Form;
	private _canRemoveItem: boolean | undefined;
	private _position: number | string | undefined;

	@Input()
	set form( value: Form ) {
		if ( typeof value === 'string' ) {
			this._form = JSON.parse(decodeURIComponent(value));
		} else {
			this._form = value;
		}
	}

	get form() {
		return this._form;
	}

	/** Position in the Form sections */
	@Input()
	set position( value: number | string | undefined ) {
		if ( typeof value === 'string' ) {
			this._position = JSON.parse(decodeURIComponent(value));
		} else {
			this._position = value;
		}
	}

	get position() {
		return this._position;
	}

	@Input()
	set canRemoveItem( value ) {
		if ( typeof value === 'string' ) {
			this._canRemoveItem = JSON.parse(decodeURIComponent(value));
		} else {
			this._canRemoveItem = value;
		}
	}

	get canRemoveItem() {
		return this._canRemoveItem;
	}

	/** Emits content data on saveForm() */
	@Output() formElementAdded = new EventEmitter<Form>();

	/** Emits removing event of this form with index */
	@Output() formElementRemoved = new EventEmitter<void>();

    @ViewChild('formTitleInput') private formTitleInput: ElementRef<HTMLInputElement>;

	@Select(ResourceAdminState.contentStateChanged)
	contentStateChanged$: Observable<boolean>;

	textboxForm: FormGroup;
	expanded = true;
	hasError = false;
	focused = false;
	saveInProgress = false;
    forceExpand = false;

	private subscriptionEnd$ = new EventEmitter<void>();

	constructor( private fb: FormBuilder ) {
		this.textboxForm = this.fb.group({
			title: ['', [Validators.required]],
			answerType: ['RICH', [Validators.required]]
		});
	}

	ngOnChanges( changes: SimpleChanges ): void {
		if ( this.form && !!this.form.uid ) {
			this.setFormContent(this.form.content as TextBoxFormContent, this.form.newAddedForm);
		}
	}

	ngOnInit() {
		const content = this.form ? this.form.content as TextBoxFormContent : null;
		if ( this.form && (!!this.form?.uid || !!content?.title) ) {
			this.setFormContent(content, this.form.newAddedForm);
		}

		this.contentStateChanged$.pipe(takeUntil(this.subscriptionEnd$)).subscribe(() => {
			this.saveInProgress = false;
		});
        this.forceExpand = this.form?.content?.forceExpand;
	}

    ngAfterViewInit(): void {
      const titleInput = this.formTitleInput?.nativeElement;
      if (this.forceExpand && titleInput) {
        setTimeout(() => {
          const titleLength = titleInput.value.length;
          titleInput.focus();
          titleInput.setSelectionRange(titleLength, titleLength);
        })
      }
    }

	ngOnDestroy() {
		this.subscriptionEnd$?.emit();
	}

	saveForm() {
		this.hasError = false;
		if ( this.textboxForm.valid ) {
			this.expanded = false;
      this.forceExpand = false;
			this.outputData();
			this.saveInProgress = true;
		} else {
			this.hasError = true;
		}
	}

	expandForm() {
		this.expanded = true;
	}

	collapseForm( event: PointerEvent ) {
		event.stopPropagation();
		this.expanded = false;
    this.forceExpand = false;
	}

	outputData() {
		const outputData: Form = {
			...this.form,
			newAddedForm: false,
			content: {
				...this.textboxForm.value,
				type: 'PRIVATE_NOTE'
			} as TextBoxFormContent
		};

		this.formElementAdded.emit(outputData);
	}

	removeForm() {
		this.formElementRemoved.emit();
	}

	private setFormContent( content: TextBoxFormContent, newAddedForm: boolean ) {
		this.expanded = newAddedForm ?? false;
		this.textboxForm.setValue({
			title: content.title,
			answerType: 'RICH'
		});
	}

}
