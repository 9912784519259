/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EditorContent, MediaUrlData } from '../../../models';

@Component({
  selector: 'ptl-resource-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceVideoPreviewComponent {

  /** Receives the EditorContent object */
  @Input() content!: EditorContent;

  downloadInProgress = false;
}
