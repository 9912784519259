/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { CardEventInfo } from '@app/app/shared/models/analytics/analytics.model';

@Component({
  selector: 'ptl-event-search',
  templateUrl: './event-search.component.html',
  styleUrls: ['./event-search.component.scss']
})
export class EventSearchComponent implements OnInit {
  @Input() cardEventsInfo: CardEventInfo[] = [];
  @Input() selectedEventId: string | null = null;
  @Output() eventSelected = new EventEmitter<string | null>();

  searchQuery = '';
  events: { id: string; name: string }[] = [];
  filteredEvents: { id: string; name: string }[] = [];
  isDropdownVisible = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.loadEvents();
    this.retainPreviousSelection();
  }

  onSearchFocus(): void {
    if (!this.selectedEventId) {
      this.isDropdownVisible = true;
      this.filterEvents();
    }
  }

  onSearchChange(): void {
    this.filterEvents();
  }

  onEventSelect(eventId: string): void {
    const selectedEvent = this.events.find(event => event.id === eventId);
    if (selectedEvent) {
      this.selectedEventId = eventId;
      this.searchQuery = selectedEvent.name;
      this.eventSelected.emit(eventId);
      this.isDropdownVisible = false;
    }
  }

  clearSelection(): void {
    this.selectedEventId = null;
    this.searchQuery = '';
    this.eventSelected.emit(null);
  }

  private loadEvents(): void {
    this.events = this.cardEventsInfo.map(event => ({
      id: event.eventCardId,
      name: event.eventHeader
    }));
    this.filteredEvents = [...this.events];
  }

  private filterEvents(): void {
    this.filteredEvents = this.events.filter(event =>
      event.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  private retainPreviousSelection(): void {
    if (this.selectedEventId) {
      const selectedEvent = this.events.find(event => event.id === this.selectedEventId);
      if (selectedEvent !== undefined) {
        this.searchQuery = selectedEvent.name;
      } else {
        this.selectedEventId = null;
      }
    }
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownVisible = false;
    }
  }
}
