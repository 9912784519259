import { EnvironmentCode } from '../../../environments/environment.model';
import { environment } from '../../../environments/environment';
import { OrganizationUi } from '../models';

export function developmentDomains(domain: string) {
  return (domain === 'localhost' || domain?.endsWith('yuna.dev.potential.ly'));
}

export function notDevelopmentDomains(domain: string) {
  return (domain !== 'localhost' && !domain?.endsWith('yuna.dev.potential.ly'));
}

export function discoveryDemo(domain: string) {
  return (domain === 'localhost' || domain === 'discovery-demo.yuna.dev.potential.ly' || domain === 'discovery-demo.potential.ly');
}

export function isBcuOrMtuDomains(domain: string): boolean {
  return (domain === 'localhost' || domain === 'bcu.potential.ly' || domain === 'mtu.potential.ly' ||
    domain === 'bcu.yuna.dev.potential.ly' || domain === 'mtu.yuna.dev.potential.ly'
  );
}

export function isV3Enabled(ui: OrganizationUi) {
  return (ui === 'v3');
}

export function getClientId(domain: string) {
  if ([EnvironmentCode.dev, EnvironmentCode.local, EnvironmentCode.devLocal, EnvironmentCode.test, EnvironmentCode.testLocal]
      .includes(environment.env) &&
    window.location.hostname.includes('localhost')) {
    return environment.clientId;
  }
  return domain;
}

