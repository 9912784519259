/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { EditorContent } from './editor-content.model';
import { LearnerFormAnswerNote } from '../../../page-modules/resource/models';

export interface Form<T = FormContent> extends EditorContent {
  uid?: string;
  content?: T;
}

export interface FormContent {
  type: FormContentType;
  title: string;
  userAnswer?: string;
  submitted: boolean;
  notes?: LearnerFormAnswerNote[];
  // front-end specific
  formIsActive: boolean;
  // front-end specific
  forceExpand?: boolean;
}

export interface TextBoxFormContent extends FormContent {
  wordLimit: number;
  minWordsLimit?: number;
  answerType: TextBoxFormContentType;
  aiAssistantEnabled: boolean;
  aiAssistantSettingsUid: string;
  isTemplate: boolean;
}

export interface ConnectedTextBoxFormContent extends FormContent {
  wordLimit: number;
  minWordsLimit?: number;
  answerType: TextBoxFormContentType;
  aiAssistantEnabled: boolean;
  aiAssistantSettingsUid: string;
  isTemplate: boolean;
  connection?: {
    cardUid: string;
    formUid: string;
    playlistUid: string;
  };
}

export type FormContentType = 'TEXTBOX'
  | 'CHECKBOX'
  | 'USER_FILE_UPLOAD'
  | 'QUIZ'
  | 'COLLECTOR'
  | 'LIST'
  | 'GROUP_LIST'
  | 'SLIDER'
  | 'FIRST_NAME'
  | 'LAST_NAME'
  | 'BOOKING'
  | 'PRIVATE_NOTE'
  | 'CONNECTED_TEXTBOX'
  | 'QUESTION_FORM';

export type TextBoxFormContentType = 'NORMAL' | 'RICH';

export interface CheckboxFormContent extends FormContent {
  title: string;
}

export interface FileUploadBoxForm {
  uid?: string;
  content: FileUploadBoxFormContent;
  newAddedForm?: boolean;
}

export interface FileUploadBoxFormContent extends FormContent {
  maxFileSizeMb: number;
  supportedFileTypes: string[];
  uploadedFile?: File;
}

export interface FileAnswer {
  fileName: string;
  path: string;
  fullPath: string;
}

export interface QuizFormContent extends FormContent {
  title: string;
  quizId: string;
}

export enum OptionOrder {
  DOCUMENT = 'DOCUMENT',
  ALPHA = 'ALPHA',
  RANDOM = 'RANDOM'
}

export enum PresentationMode {
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  SELECTABLE_DROP_DOWN = 'SELECTABLE_DROP_DOWN'
}

export interface FormQuestionContent extends FormContent {
  uid: string | null;
  presentationMode: PresentationMode;
  optionsListUid: string | null;
  optionOrder: OptionOrder;
  maxSelection: number;
}

export interface CollectorFormContent extends FormContent {
  collectorId?: string;
  uid?: string;
}

export interface SliderFormContent extends FormContent {
  title: string;
  min: number;
  max: number;
  type: FormContentType;
}

export interface FirstNameFormContent extends FormContent {
  title: string;
  type: FormContentType;
}

export interface LastNameFormContent extends FormContent {
  title: string;
  type: FormContentType;
}

export interface ListFormContent extends FormContent {
  title: string;
  list: HierarchicalList;
  type: FormContentType;
}

export interface HierarchicalList {
  _id: string;
  title: {
    languageCode: string;
    value: string;
  }[];
  creatorUid: string;
  createdAt: Date;
  lastUpdatedAt: Date;
  root: TextElement | GroupElement;
}

export interface TextElement {
  uid: string;
  value: string;
  sublist: TextElement[];
}

export interface GroupElement {
  uid: string;
  value: Group;
  sublist: GroupElement[];
}

export interface Group {
  id: string;
  name: string;
}

export interface NestedChildData {
  data: {
    $event: NestedChildData | GroupElement[];
    i: number;
    uid: string;
    children: GroupElement[];
    value: string;
  };
  fromChild: true;
}

export interface BookingFormContent extends FormContent {
  bookableAppointmentId?: string;
}
