/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { CardEventRegistrations, PlaylistMemberMetrics } from '@app/app/shared/models/analytics/analytics.model';
import { PlaylistDataViewModel } from '@app/app/page-modules/pages/components/edit/analytics/page-analytics.model';
import { TranslationService } from '@app/app/shared/services/translation/translation.service';
import * as moment from 'moment';
import { exportToCsv } from '@app/app/shared/utils/analytics.utils';

@Component({
  selector: 'ptl-details-dialog',
  templateUrl: './playlist-metrics-table-dialog.component.html',
  styleUrls: ['./playlist-metrics-table-dialog.component.scss']
})
export class PlaylistMetricsTableDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = [
    'header',
    'totalCards',
    'totalMembers',
    'startedCourseCount',
    'completedCourseCount',
    'totalEventCardRegistrations',
    'totalEnrollmentsCount'
  ];
  dataSource: MatTableDataSource<PlaylistDataViewModel> = new MatTableDataSource();

  private pageHeadline: string;

  constructor(
    public dialogRef: MatDialogRef<PlaylistMetricsTableDialogComponent>,
    private translationService: TranslationService,
    @Inject(MAT_DIALOG_DATA) public data: {
      pageHeadline: string;
      metrics: {
        header: string;
        metrics: PlaylistMemberMetrics;
      }[];
    }
  ) { }

  ngOnInit(): void {
    this.dataSource.data = this.data.metrics.map(({ header, metrics }) => ({
      header: header,
      totalCards: metrics.summary.totalCards,
      totalMembers: metrics.summary.totalMembers,
      startedCourseCount: metrics.summary.startedCourseCount,
      completedCourseCount: metrics.summary.completedCourseCount,
      totalEventCardRegistrations: this.countEventCardRegistrations(
        metrics.cardMemberMetrics.cardEventRegistrations
      ),
      totalEnrollmentsCount: metrics.summary.totalEnrollmentsCount
    }));

    this.pageHeadline = this.data.pageHeadline;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  downloadCsvFile(): void {
    const headers = [
      this.translationService.getTranslation('global.label.course'),
      this.translationService.getTranslation('analytics.metric.cards'),
      this.translationService.getTranslation('members.title'),
      this.translationService.getTranslation('analytics.metric.startedCourses'),
      this.translationService.getTranslation('analytics.metric.completedCourses'),
      this.translationService.getTranslation('analytics.metric.eventCardRegistrations'),
      this.translationService.getTranslation('analytics.metric.totalEnrollments')
    ];

    const rows = this.dataSource.data.map(row => [
      row.header,
      row.totalCards,
      row.totalMembers,
      row.startedCourseCount,
      row.completedCourseCount,
      row.totalEventCardRegistrations,
      row.totalEnrollmentsCount
    ]);

    const time = moment().format('YYYY-MM-DD_HH-mm-ss');
    const fileName = `${this.pageHeadline || 'metrics'}_courses_${time}.csv`;

    exportToCsv(headers, rows, fileName);
  }

  private countEventCardRegistrations(cardEventRegistrations: CardEventRegistrations[]): number {
    return cardEventRegistrations.filter(
      (registration) =>
        registration.userEventSummary &&
        registration.userEventSummary.registeredOn
    ).length;
  }
}
