<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div *ngIf="pieSegments.length > 0" class="chart-dashboard">
  <p class="chart-dashboard-title">
    {{ 'translations.analytics.charts.totalCompletions.title' | transloco }}
  </p>
  <p class="chart-dashboard-description">
    <ng-container *ngIf="requirementsMode === 'SPECIFIC'; else partOfMode">
      {{ 'translations.analytics.charts.totalCompletions.description.specific' | transloco }}
    </ng-container>
    <ng-template #partOfMode>
      {{ 'translations.analytics.charts.totalCompletions.description.partOf' | transloco }}
    </ng-template>
  </p>

  <div class="pie-chart-container">
    <svg class="pie-chart" viewBox="0 0 40 40">
      <circle
        *ngFor="let segment of pieSegments; let i = index"
        class="pie-segment"
        [attr.stroke]="segment.color"
        [attr.stroke-dasharray]="segment.percentage + ' ' + (100 - segment.percentage)"
        [attr.stroke-dashoffset]="-calculateOffset(i)"
        cx="20"
        cy="20"
        r="15.5"
        (mouseenter)="showTooltip($event, segment)"
        (mouseleave)="hideTooltip()"
      ></circle>
    </svg>

    <ul class="pie-chart-legend">
      <li *ngFor="let segment of pieSegments">
        <span class="legend-color" [style.background]="segment.color"></span>
        <span class="legend-label">{{ segment.label }}</span>
        <span class="legend-value">{{ segment.value }} ({{ segment.percentage.toFixed(1) }}%)</span>
      </li>
    </ul>
  </div>

  <div *ngIf="tooltipVisible" class="tooltip" [style.left.px]="tooltipX" [style.top.px]="tooltipY">
    <strong>{{ tooltipContent.label }}</strong><br />
    {{
      tooltipContent.value | i18nPlural: {
        '=1': 'translations.global.label.oneUser' | transloco,
        'other': 'translations.global.label.countUsers' | transloco: {
          count: tooltipContent.value
        }
      }
    }}<br />
    {{ tooltipContent.percentage.toFixed(1) }}%
  </div>
</div>
