<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="upload-progress-bar-container">
  <div class="upload-progress-bar-description" [ngSwitch]="content.mediaType">
    <span *ngSwitchCase="'STREAM_VIDEO'">{{ 'translations.videoUploadInfo' | transloco }}</span>
    <span *ngSwitchCase="'AUDIO'">{{ 'translations.audioUploadInfo' | transloco }}</span>
    <span *ngSwitchCase="'IMAGE'">{{ 'translations.imageUploadInfo' | transloco }}</span>
    <span *ngSwitchCase="'DOCUMENT'">{{ 'translations.documentUploadInfo' | transloco }}</span>
  </div>
  <div class="upload-progress-bar-wrapper">
    <div class="upload-progress-bar">
      <div class="upload-progress" [style.width.%]="uploadProgress"></div>
    </div>
    <div class="upload-progress-percent">
      {{ uploadProgress ? uploadProgress : '0'}}%
    </div>
  </div>
  <div class="upload-progress-bar-filename" *ngIf="content?.fileName && !content.fileName.startsWith('http')">
    {{ content.fileName }}
  </div>
</div>
