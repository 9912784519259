<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="editor f_editor">
  <ptl-editor-content
    #editorContent
    [editorOptions]="editorOptions"
    [placeholder]="placeholder"
    [content]="content"
    [chartSectionUid]="chartSectionUid"
    [disableAutoFocus]="disableAutoFocus"
    [upload]="upload"
    [toolbarOpen]="toolbarOpen"
    [canRemoveItem]="canRemoveItem"
    [emptyContentDisable]="emptyContentDisable"
    [bytePlaceholder]="bytePlaceholder"
    (removeContentItem)="onContentRemoval($event)"
    (updateContentItem)="onUpdateContentItem($event)"
    (createEmptyParagraph)="onAddEmptyParagraphAtIndex($event)"
    (replaceRegularLink)="onReplaceRegularLink($event)"
    (toolbarUpdate)="onToolbarUpdate($event)"
    (inputFocus)="onInputFocus()"
    (inputBlur)="onInputBlur()"
    (uploadContentItem)="onUploadContent($event)"
    (focus)="onEmptyContentFocus()"
    (click)="onEmptyContentFocus()"
    (keydown.enter)="onEmptyContentFocus()">
  </ptl-editor-content>
</div>
