/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '../environments/environment';

import { MainComponent } from './index/components/main/main.component';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from './page-modules/account/services/account-data.service';
import { ApiAccountDataService } from './page-modules/account/services/api-account-data.service';
import { MockAccountDataService } from './page-modules/account/services/mock-account-data.service';


import { AppRoutingModule } from './routing/routing.module';
import { DialogService } from './shared/helpers/dialog/dialog.service';
import { ApiSharedCardDataService } from './shared/services/card/api-card-data.service';
import { SHARED_CARD_DATA_SERVICE, SharedCardDataService } from './shared/services/card/card-data.service';
import { MockSharedCardDataService } from './shared/services/card/mock-card-data.service';
import { ApiCollectorDataService } from './shared/services/collector/api-collector-data.service';
import { COLLECTOR_DATA_SERVICE, CollectorDataService } from './shared/services/collector/collector-data.service';
import { MockCollectorDataService } from './shared/services/collector/mock-collector-data.service';
import { CORE_COMPETENCE_DATA_SERVICE } from './shared/services/competence/competence-data.service';
import { AwsFileUploadService } from './shared/services/file-upload/aws-file-upload.service';
import {
  FileUploadService,
  ORGANIZATIONS_FILE_UPLOAD_DATA_SERVICE,
  RESOURCES_FILE_UPLOAD_DATA_SERVICE
} from './shared/services/file-upload/file-upload.service';
import { MockFileUploadService } from './shared/services/file-upload/mock-file-upload.service';
import { HttpConfigInterceptor } from './shared/services/http-config-interceptor';
import { ApiLearnerPlaylistDataService } from './shared/services/learner-playlist/api-data.service';

import { TranslocoService } from '@ngneat/transloco';
import { forkJoin } from 'rxjs';
import { ApiBookingDataService } from './shared/services/booking/api-booking-data.service';
import { BOOKING_DATA_SERVICE, BookingDataService } from './shared/services/booking/booking-data.service';
import { MockBookingDataService } from './shared/services/booking/mock-booking-data.service';
import { ApiDataFormDataService } from './shared/services/data-form/api-data-form-data.service';
import { DATA_FORM_DATA_SERVICE, DataFormDataService } from './shared/services/data-form/data-form-data.service';
import { MockDataFormDataService } from './shared/services/data-form/mock-data-form-data.service';
import { ApiGroupListDataService } from './shared/services/group-list/api-group-list-data.service';
import { GROUP_LIST_DATA_SERVICE, GroupListDataService } from './shared/services/group-list/group-list-data.service';
import { MockGroupListDataService } from './shared/services/group-list/mock-group-list-data.service';
import { ApiLanguagesDataService } from './shared/services/languages/api-languages-data.service';
import { LANGUAGES_DATA_SERVICE, LanguagesDataService } from './shared/services/languages/languages-data.service';
import { MockLanguagesDataService } from './shared/services/languages/mock-languages-data.service';
import {
  LEARNER_PLAYLIST_DATA_SERVICE,
  LearnerPlaylistDataService
} from './shared/services/learner-playlist/data.service';
import { MockLearnerPlaylistDataService } from './shared/services/learner-playlist/mock-data.service';
import { ApiLikeInfoDataService } from './shared/services/like-info/api-like-info-data.service';
import { LIKE_INFO_DATA_SERVICE, LikeInfoDataService } from './shared/services/like-info/like-info-data.service';
import { LikeInfoSubscriptionService } from './shared/services/like-info/like-info-subscription.service';
import { MockLikeInfoDataService } from './shared/services/like-info/mock-like-info-data.service';
import { ApiOptionListDataService } from './shared/services/option-list/api-option-list-data.service';
import { MockOptionListDataService } from './shared/services/option-list/mock-option-list-data.service';
import {
  OPTION_LIST_DATA_SERVICE,
  OptionListDataService
} from './shared/services/option-list/option-list-data.service';
import {
  ApiOrganizationRetrievalDataService
} from '@app/app/shared/services/organization-retrieval/api-organization-retrieval-data.service';
import {
  MockOrganizationRetrievalDataService
} from '@app/app/shared/services/organization-retrieval/mock-organization-retrieval-data.service';
import {
  ORGANIZATION_RETRIEVAL_DATA_SERVICE,
  OrganizationRetrievalDataService
} from '@app/app/shared/services/organization-retrieval/organization-retrieval-data.service';
import { PageTitleService } from './shared/services/page-title/page-title.service';
import { ApiPIDataService } from './shared/services/pi/api-pi-data.service';
import { MockPIDataService } from './shared/services/pi/mock-pi-data.service';
import { PIDataService, PI_DATA_SERVICE } from './shared/services/pi/pi-data.service';
import { PreviousRouteService } from './shared/services/previous-router.service';
import { RestClientService } from './shared/services/rest-client.service';
import { ApiSharedReviewDataService } from './shared/services/review/api-review-data.service';
import { MockSharedReviewDataService } from './shared/services/review/mock-review-data.service';
import { SHARED_REVIEW_DATA_SERVICE, SharedReviewDataService } from './shared/services/review/review-data.service';
import { ApiSidebarTreeDataService } from './shared/services/sidebar-tree/api-sidebar-tree-data.service';
import { MockSidebarTreeDataService } from './shared/services/sidebar-tree/sidebar-tree-card-data.service';
import {
  SIDEBAR_TREE_DATA_SERVICE,
  SidebarTreeDataService
} from './shared/services/sidebar-tree/sidebar-tree-data.service';
import { ApiSubheaderDataService } from './shared/services/subheader/api-subheader-data.service';
import { MockSubheaderDataService } from './shared/services/subheader/mock-subheader-data.service';
import { SUBHEADER_DATA_SERVICE, SubheaderDataService } from './shared/services/subheader/subheader-data.service';
import { ApiTagDataService } from './shared/services/tags/api-tag-data.service';
import { MockTagDataService } from './shared/services/tags/mock-tag-data.service';
import { CORE_TAG_DATA_SERVICE, TagDataService } from './shared/services/tags/tag-data.service';
import { MockToastService } from './shared/services/toast-notifications/mock-toast-service';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from './shared/services/toast-notifications/toast-service';
import { ToastServiceImpl } from './shared/services/toast-notifications/toast-service-impl';
import {
  DEFAULT_LANGUAGE_CODE,
  LANGUAGE_KEY,
  TranslationService
} from './shared/services/translation/translation.service';
import { ApiWebsocketService } from './shared/services/websocket/api-websocket.service';
import { MockWebsocketService } from './shared/services/websocket/mock-websocket.service';
import { WEBSOCKET_SERVICE, WebsocketService } from './shared/services/websocket/websocket.service';
import * as SharedFactoryService from './shared/shared-service-factory';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { AccessTokenService } from './user-auth/services/access-token.service';

import { ShareService } from '@app/app/shared/services/share/share.service';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { PAGES_DATA_SERVICE } from './page-modules/pages/services/data.service';
import { PROJECT_DATA_SERVICE } from './page-modules/project/services/data.service';
import { ApiSharedListsDataService } from './shared/services/lists/api-lists-data.service';
import { SHARED_LISTS_DATA_SERVICE, SharedListsDataService } from './shared/services/lists/lists-data.service';
import { MockSharedListsDataService } from './shared/services/lists/mock-lists-data.service';
import { MaintenanceService } from './shared/services/maintenance/maintenance.service';
import { RequestQueueManagerService } from './shared/services/request-queue-manager/request-queue-manager';

SwiperCore.use([Navigation, Pagination]);

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslocoRootModule,
  ],
  providers: [
    AccessTokenService,
    MaintenanceService,
    RestClientService,
    PageTitleService,
    PreviousRouteService,
    LikeInfoSubscriptionService,
    DialogService,
    TranslationService,
    RequestQueueManagerService,
    ShareService,
    {
      provide: LEARNER_PLAYLIST_DATA_SERVICE,
      useFactory: learnerPlaylistServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: CORE_TAG_DATA_SERVICE,
      useFactory: tagServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: CORE_COMPETENCE_DATA_SERVICE,
      useFactory: SharedFactoryService.competenceServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: ORGANIZATION_RETRIEVAL_DATA_SERVICE,
      useFactory: organizationRetrievalServiceFactory,
      deps: [RestClientService],
    },
    {
      provide: RESOURCES_FILE_UPLOAD_DATA_SERVICE,
      useFactory: resourcesFileUploadServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: ORGANIZATIONS_FILE_UPLOAD_DATA_SERVICE,
      useFactory: organizationsFileUploadServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: SHARED_CARD_DATA_SERVICE,
      useFactory: sharedResourceServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: SIDEBAR_TREE_DATA_SERVICE,
      useFactory: sharedSidebarTreeServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: SHARED_REVIEW_DATA_SERVICE,
      useFactory: sharedReviewServiceFactory,
      deps: [RestClientService],
    },
    {
      provide: COLLECTOR_DATA_SERVICE,
      useFactory: collectorServiceFactory,
      deps: [RestClientService],
    },
    {
      provide: BOOKING_DATA_SERVICE,
      useFactory: bookingServiceFactory,
      deps: [RestClientService],
    },
    {
      provide: ACCOUNT_DATA_SERVICE,
      useFactory: accountServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: PAGES_DATA_SERVICE,
      useFactory: SharedFactoryService.pageServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: PI_DATA_SERVICE,
      useFactory: piServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: LIKE_INFO_DATA_SERVICE,
      useFactory: likeInfoServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: SUBHEADER_DATA_SERVICE,
      useFactory: subheaderServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: LANGUAGES_DATA_SERVICE,
      useFactory: languagesServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: DATA_FORM_DATA_SERVICE,
      useFactory: dataFormServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: GROUP_LIST_DATA_SERVICE,
      useFactory: groupListServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: OPTION_LIST_DATA_SERVICE,
      useFactory: optionListServiceFactory,
      deps: [RestClientService],
    },
    {
      provide: WEBSOCKET_SERVICE,
      useFactory: websocketServiceFactory,
      deps: [AccessTokenService],
    },
    {
      provide: TOAST_NOTIFICATION_SERVICE,
      useFactory: toastServiceFactory,
      deps: [NgZone],
    },
    {
      provide: SHARED_LISTS_DATA_SERVICE,
      useFactory: sharedListsServiceFactory,
      deps: [RestClientService, TranslationService]
    },
    {
      provide: PROJECT_DATA_SERVICE,
      useFactory: SharedFactoryService.projectServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslocoService],
      multi: true,
    },
  ],
  bootstrap: [MainComponent],
})
export class AppModule {
}

// -------------------------------------------------------------------------
export function learnerPlaylistServiceFactory(
  client: RestClientService, translationService: TranslationService
): LearnerPlaylistDataService {
  if (environment.dataProvider === 'mock') {
    return new MockLearnerPlaylistDataService();
  }

  return new ApiLearnerPlaylistDataService(client, translationService);
}

export function tagServiceFactory(client: RestClientService, translationService: TranslationService): TagDataService {
  if (environment.dataProvider === 'mock') {
    return new MockTagDataService();
  }

  return new ApiTagDataService(client, translationService);
}

// -------------------------------------------------------------------------
/** Provides the resource file-upload data service. */
export function resourcesFileUploadServiceFactory(client: RestClientService, translationService: TranslationService): FileUploadService {
  return fileUploadServiceFactory(client, translationService);
}

// -------------------------------------------------------------------------
/** Provides the organizations file-upload data service. */
export function organizationsFileUploadServiceFactory(
  client: RestClientService, translationService: TranslationService
): FileUploadService {
  return fileUploadServiceFactory(client, translationService);
}

// -------------------------------------------------------------------------
/** Provides the organizations file-upload data service. */
export function fileUploadServiceFactory(client: RestClientService, translationService: TranslationService): FileUploadService {
  if (environment.dataProvider === 'mock') {
    return new MockFileUploadService();
  }

  return new AwsFileUploadService(client, translationService);
}

// -------------------------------------------------------------------------
/** Provides the org details retrieval data service. */
export function organizationRetrievalServiceFactory(client: RestClientService, translationService: TranslationService):
  OrganizationRetrievalDataService {
  if (environment.dataProvider === 'mock') {
    return new MockOrganizationRetrievalDataService();
  }

  return new ApiOrganizationRetrievalDataService(client, translationService);
}

// -------------------------------------------------------------------------
/** Provides shared resources data service. */
export function sharedResourceServiceFactory(client: RestClientService, translationService: TranslationService): SharedCardDataService {
  if (environment.dataProvider === 'mock') {
    return new MockSharedCardDataService();
  }

  return new ApiSharedCardDataService(client, translationService);
}

// -------------------------------------------------------------------------
/** Provides sidebar tree data service. */
export function sharedSidebarTreeServiceFactory(client: RestClientService, translationService: TranslationService): SidebarTreeDataService {
  if (environment.dataProvider === 'mock') {
    return new MockSidebarTreeDataService();
  }

  return new ApiSidebarTreeDataService(client, translationService);
}

// -------------------------------------------------------------------------
/** Provides shared review data service. */
export function sharedReviewServiceFactory(client: RestClientService): SharedReviewDataService {
  if (environment.dataProvider === 'mock') {
    return new MockSharedReviewDataService();
  }

  return new ApiSharedReviewDataService(client);
}

export function collectorServiceFactory(client: RestClientService): CollectorDataService {
  if (environment.dataProvider === 'mock') {
    return new MockCollectorDataService();
  }

  return new ApiCollectorDataService(client);
}

export function bookingServiceFactory(client: RestClientService, translationService: TranslationService): BookingDataService {
  if (environment.dataProvider === 'mock') {
    return new MockBookingDataService();
  }

  return new ApiBookingDataService(client, translationService);
}

/** Provides the account data service. */
export function accountServiceFactory(client: RestClientService, translationService: TranslationService): AccountDataService {
  if (environment.dataProvider === 'mock') {
    return new MockAccountDataService();
  }

  return new ApiAccountDataService(client, translationService);
}

export function piServiceFactory(client: RestClientService, translationService: TranslationService): PIDataService {
  if (environment.dataProvider === 'mock') {
    return new MockPIDataService();
  }

  return new ApiPIDataService(client, translationService);
}

// -------------------------------------------------------------------------
/** Provides like info data service. */
export function likeInfoServiceFactory(client: RestClientService, translationService: TranslationService): LikeInfoDataService {
  if (environment.dataProvider === 'mock') {
    return new MockLikeInfoDataService();
  }

  return new ApiLikeInfoDataService(client, translationService);
}

// -------------------------------------------------------------------------
/** Provides subheader data service. */
export function subheaderServiceFactory(client: RestClientService, translationService: TranslationService): SubheaderDataService {
  if (environment.dataProvider === 'mock') {
    return new MockSubheaderDataService();
  }

  return new ApiSubheaderDataService(client, translationService);
}

export function languagesServiceFactory(client: RestClientService, translationService: TranslationService): LanguagesDataService {
  if (environment.dataProvider === 'mock') {
    return new MockLanguagesDataService();
  }

  return new ApiLanguagesDataService(client, translationService);
}

export function dataFormServiceFactory(client: RestClientService, translationService: TranslationService): DataFormDataService {
  if (environment.dataProvider === 'mock') {
    return new MockDataFormDataService();
  }

  return new ApiDataFormDataService(client, translationService);
}

export function optionListServiceFactory(client: RestClientService): OptionListDataService {
  if (environment.dataProvider === 'mock') {
    return new MockOptionListDataService();
  }

  return new ApiOptionListDataService(client);
}

export function groupListServiceFactory(client: RestClientService, translationService: TranslationService): GroupListDataService {
  if (environment.dataProvider === 'mock') {
    return new MockGroupListDataService();
  }

  return new ApiGroupListDataService(client, translationService);
}

export function websocketServiceFactory(accessTokenService: AccessTokenService): WebsocketService {
  if (environment.dataProvider === 'mock') {
    return new MockWebsocketService();
  }

  return new ApiWebsocketService(accessTokenService);
}

export function toastServiceFactory(ngZone: NgZone): ToastService {
  if (environment.dataProvider === 'mock') {
    return new MockToastService();
  }
  return new ToastServiceImpl(ngZone);
}

export function appInitializerFactory(transloco: TranslocoService) {
  return () => {
    let language = localStorage.getItem(LANGUAGE_KEY);
    if (!language) {
      language = DEFAULT_LANGUAGE_CODE;
    }
    transloco.setActiveLang(language);
    return forkJoin([
      transloco.load(language),
    ]);
  };
}

export function sharedListsServiceFactory(client: RestClientService, translationService: TranslationService): SharedListsDataService {
  if (environment.dataProvider === 'mock') {
    return new MockSharedListsDataService();
  }

  return new ApiSharedListsDataService(client, translationService);
}
