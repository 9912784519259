/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PlaylistMemberMetrics } from '@app/app/shared/models/analytics/analytics.model';

@Component({
  selector: 'ptl-course-engagements-chart',
  templateUrl: './course-engagements-chart.component.html',
  styleUrls: ['../common-analytics-chart-styles.scss', './course-engagements-chart.component.scss']
})
export class CourseEngagementsChartComponent implements OnChanges {
  @Input() individualPlaylistMetrics: { header: string; metrics: PlaylistMemberMetrics }[] = [];
  @Input() fixedHeight = false;
  @Input() showPlaceholder = false;

  courseEngagementData: { header: string; totalEngagements: number }[] = [];
  sortOrder: 'asc' | 'desc' = 'desc';
  maxTotalEngagements = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['individualPlaylistMetrics']) {
      this.updateCourseEngagementData();
    }
  }

  toggleSortOrder(): void {
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    this.sortCourseEngagementData();
  }

  private updateCourseEngagementData(): void {
    const aggregatedData: { header: string; totalEngagements: number }[] = [];

    this.individualPlaylistMetrics.forEach((playlist) => {
      const totalEngagements = Object.values(playlist.metrics.cardMemberMetrics.cardEngagements).reduce((sum, value) => sum + value, 0);
      aggregatedData.push({ header: playlist.header, totalEngagements });
    });

    this.courseEngagementData = aggregatedData;
    this.maxTotalEngagements = Math.max(...aggregatedData.map((data) => data.totalEngagements), 0);
    this.sortCourseEngagementData();
  }

  private sortCourseEngagementData(): void {
    this.courseEngagementData.sort((a, b) => {
      return this.sortOrder === 'desc' ? b.totalEngagements - a.totalEngagements : a.totalEngagements - b.totalEngagements;
    });
  }
}
