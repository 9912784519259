/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input, NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  CollectorFormContent,
  EditorContent,
  EditorContentRemovalEvent,
  EditorContentUpdateEvent,
  Form,
  MediaUploadData,
  Organization,
  Resource,
  ResourceSection
} from '../../../../shared/models';
import {
  COLLECTOR_DATA_SERVICE,
  CollectorDataService
} from '../../../../shared/services/collector/collector-data.service';
import { EditorHelper } from '../../../../shared/helpers/editor.helper';
import {
  Collector,
  CollectorConfig,
  CollectorConfigRequest,
  CollectorFrameworks,
  CollectorInstruction,
  CollectorLogFrameworksResponse,
  CollectorTags,
  CollectorTagsResponse, CollectorUpdateRequest
} from '@app/app/shared/models/editor/collector-content.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { FileUploadHelper } from '@app/app/shared/helpers/file-upload-helper';
import {
  FileUploadService,
  RESOURCES_FILE_UPLOAD_DATA_SERVICE
} from '@app/app/shared/services/file-upload/file-upload.service';
import { EditorTransformer } from '@app/app/shared/helpers/editor-transformer';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { CurrentLanguage } from '@app/app/page-modules/resource/store/admin/resource-event-admin.state.model';
import { UserAuthState } from '@app/app/user-auth/store/user-auth.state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '@app/app/shared/services/translation/translation.service';
import { SnackbarHelper } from '@app/app/shared/helpers/snackbar-helper';
import { CollectorFormContentComponent } from './collector-form-content/collector-form-content.component';
import { ResourceAdminState } from '@app/app/page-modules/resource/store/admin/resource-admin.state';

@Component({
  selector: 'ptl-collector',
  templateUrl: './collector.component.html',
  styleUrls: ['./collector.component.scss'],
})
export class CollectorComponent implements OnInit, OnDestroy, OnChanges {

  private _form: Form<CollectorFormContent> | undefined;
  private _canRemoveItem = true;

  @Input()
  set form(value: Form | undefined) {
    if (typeof value === 'string') {
      this._form = JSON.parse(decodeURIComponent(value));
    } else {
      this._form = value;
    }
  }

  get form() {
    return this._form;
  }

  @Input()
  set canRemoveItem(value) {
    if (typeof value === 'string') {
      this._canRemoveItem = JSON.parse(decodeURIComponent(value));
    } else {
      this._canRemoveItem = value;
    }
  }

  get canRemoveItem() {
    return this._canRemoveItem;
  }

  /** Emits removing of the formContent event */
  @Output() removeCollectorContent = new EventEmitter<void>();

  /** Emits the update of the formContent event */
  @Output() updateCollectorContent = new EventEmitter<Form>();

  @ViewChild('collectorHtml') private collectorHtml: ElementRef;

  @ViewChild('collectorFormContent') private collectorFormContent: CollectorFormContentComponent;

  @Select(ResourceAdminState.currentLanguage)
  private currentLanguage$: Observable<CurrentLanguage>;

  @Select(UserAuthState.organizationDetails)
  private organization$: Observable<Organization>;

  expanded = false;
  collectorContent: CollectorFormContent;
  collectorForm: FormGroup;
  collectorConfig: CollectorConfigRequest;
  collectorTags: CollectorTagsResponse;
  collectorFrameworks: CollectorLogFrameworksResponse[] = [];
  isButtonClicked = false;
  saveInProgress = false;

  instruction: CollectorInstruction = {
    dynamicContent: [],
  };

  private resourceUid: string;
  private collectorLoaded: boolean;
  private languageCode: string;
  private organization: Organization;
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef,
    @Inject(RESOURCES_FILE_UPLOAD_DATA_SERVICE) private fileUploadService: FileUploadService,
    @Inject(COLLECTOR_DATA_SERVICE) private collectorDataService: CollectorDataService
  ) {
    this.resourceUid = this.store.selectSnapshot(ResourceAdminState.resourceUid);
  }

  ngOnInit() {
    this.collectorForm = this.fb.group({
      title: ['', [Validators.required]],
    });
    if (this.form && !!this.form.uid) {
      this.collectorContent = this.form.content as CollectorFormContent;
    }
    this.currentLanguage$.pipe(takeUntil(this.subscriptionEnd$))
      .subscribe(language => {
        this.languageCode = language?.supportedLanguage?.language?.code;
        if (this.collectorContent?.collectorId && !this.collectorLoaded) {
          this.getCollector(this.collectorContent?.collectorId);
          this.collectorLoaded = true;
        }
      });
    this.organization$.pipe(take(1)).subscribe(org => this.organization = org);
  }

  ngOnDestroy() {
    this.subscriptionEnd$.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.collectorContent) {
      if (this.form && !!this.form.uid) {
        this.collectorContent = this.form.content as CollectorFormContent;
      }
    }
    if (changes.form && changes.form.previousValue && changes.form.currentValue) {
      const currentCollectorUid = changes.form?.currentValue?.content.collectorId;
      const previousCollectorUid = changes.form?.previousValue?.content.collectorId;
      // Handle language change
      if (this.collectorLoaded && previousCollectorUid === currentCollectorUid) {
        this.getCollector(currentCollectorUid);
      }
      // Handle case when previous state undefined e.g. creating new collector
      if (previousCollectorUid === undefined && previousCollectorUid !== currentCollectorUid) {
        this.getCollector(currentCollectorUid);
      }
    }
  }

  onCollectorFieldsUpdate(data: CollectorConfigRequest) {
    this.collectorConfig = data;
  }

  updateCollector() {
    if (this.collectorForm.invalid) {
      return;
    }
    this.isButtonClicked = true;
    const request = {
      title: {
        languageCode: this.languageCode,
        value: this.collectorForm.get('title').value,
      },
      instruction: this.instruction.dynamicContent,
      config: {
        timeLogConfig: this.collectorConfig?.timeLogConfig,
        logType: this.collectorConfig?.logType,
        evidencesType: this.collectorConfig?.evidencesType,
        frameworks: this.collectorConfig?.frameworks,
        tags: this.collectorConfig?.tags,
        requiredNumberOfLogs: this.collectorConfig?.requiredNumberOfLogs,
        logTemplate: this.getLogTemplate(),
      },
    };
    if (!this.isValidRequest(request, this.collectorConfig.definedTagsCount)) {
      this.isButtonClicked = false;
      return;
    }
    this.saveInProgress = true;
    this.collectorDataService.updateCollector(this.resourceUid, this.collectorContent.collectorId, request, this.languageCode)
      .subscribe(({ isSuccess, value }) => {
        this.isButtonClicked = false;
        this.saveInProgress = false;
        if (isSuccess) {
          if (this.collectorConfig.logTemplate) {
            for (let i = 0; i < value.config.logTemplate.length; i++) {
              if (value.config.logTemplate[i].type === 'MEDIA_UPLOAD') {
                const mediaContent = this.collectorConfig.logTemplate[i] as MediaUploadData;
                this.collectorConfig.logTemplate[i] = {
                  ...value.config.logTemplate[i],
                  file: mediaContent.file,
                } as MediaUploadData;
              }
            }
            const sectionToSave = { dynamicContent: [...this.collectorConfig.logTemplate] };
            this.uploadFiles(sectionToSave as ResourceSection);
          }
          this.setCollectorData(value);
          this.expandForm(false);
        } else {
          this.expandForm(true);
        }
      });
    this.form.content.title = this.collectorForm.get('title').value;
    this.updateCollectorContent.emit(this.form);
  }

  removeCollector() {
    if (this.collectorContent && this.collectorContent.collectorId) {
      this.collectorDataService.deleteCollector(this.resourceUid, this.collectorContent.collectorId)
        .subscribe(({ isSuccess }) => {
          if (isSuccess) {
            this.removeCollectorContent.emit();
          }
        });
    } else {
      this.removeCollectorContent.emit();
    }
  }

  expandForm(expand: boolean = true) {
    this.expanded = expand;

    setTimeout(() => {
      this.cdr.detectChanges();
    }, 0)
  }

  collapseForm(event: PointerEvent) {
    event.stopPropagation();
    this.expandForm(false);
  }

  onContentUpdate({ index, update }: EditorContentUpdateEvent, level: CollectorInstruction) {
    level.dynamicContent = level.dynamicContent.map((editorContent, i) =>
      i === index ? update.newContent : editorContent);
  }

  onContentRemoval({ index }: EditorContentRemovalEvent, level: CollectorInstruction) {
    const filteredContent = level.dynamicContent.filter((_, i) => i !== index);
    level.dynamicContent = EditorHelper.joinSuccessiveParagraphContent(filteredContent);
  }

  onContentCreation(content: EditorContent, level: CollectorInstruction) {
    level.dynamicContent = EditorHelper.addDynamicContentWithBreakpointCheck(level.dynamicContent, content);
  }

  private getCollector(collectorUid: string) {
    if (this.collectorContent) {
      this.collectorDataService.getCollector(this.resourceUid, collectorUid, this.languageCode)
        .subscribe(({ isSuccess, value }) => {
          this.collectorLoaded = true;
          if (isSuccess) {
            this.setCollectorData(value);
          }
        });
    }
  }

  private setCollectorData(value: Collector) {
    this.instruction.dynamicContent = value.instructions;
    const collectorTitle = LanguageCodeHelper
      .getDataByUserLanguageCode(value.title, this.organization, this.languageCode).value;
    this.collectorForm.setValue({
      title: collectorTitle,
    });
    if (value.config) {
      this.collectorConfig = this.getCollectorConfig(value.config);
    }
    this.expandForm(this.form.newAddedForm ?? false);

    this.cdr.detectChanges();
  }

  private getLogTemplate() {
    if (this.collectorConfig.logTemplate) {
      const template = [...this.collectorConfig.logTemplate];
      return template.map(item => EditorTransformer.transformEditorContentToMatchApiExpectations(item));
    }
    return [];
  }

  private getCollectorConfig(config: CollectorConfig): CollectorConfigRequest {
    this.collectorTags = config.logTags ? config.logTags : undefined;
    this.collectorFrameworks = config.logFrameworks ? config.logFrameworks : [];
    const data = {
      ...config,
      logType: config.type,
      tags: config.logTags ? this.formatTagsData(config.logTags) : null,
      frameworks: (config.logFrameworks && config.logFrameworks.length) ? this.formatFrameworksData(config.logFrameworks) : null,
    };
    delete data.type;
    return data;
  }

  private formatTagsData(tags: CollectorTagsResponse): CollectorTags {
    return {
      minRequiredTags: tags.minRequiredTags,
      tagUids: tags.tags.map(tag => tag._id),
    };
  }

  private formatFrameworksData(frameworks: CollectorLogFrameworksResponse[]): CollectorFrameworks[] {
    return [{
      minRequiredTags: frameworks[0].minRequiredTags,
      frameworkUid: frameworks[0].framework._id,
    }];
  }

  private uploadFiles(sectionToSave: ResourceSection) {
    for (const item of sectionToSave.dynamicContent) {
      if (item.type === 'MEDIA_UPLOAD' && !!(item as MediaUploadData).file) {
        const filePath = FileUploadHelper.filePath(FileUploadHelper.getFullUrl(item as MediaUploadData));
        this.fileUploadService.uploadFile(filePath, (item as MediaUploadData).file).subscribe();
      }
    }
  }

  private isValidRequest(data: CollectorUpdateRequest, definedTagsCount: number) {
    let isValid = true;
    this.collectorFormContent.tagsCountError = false;
    if (definedTagsCount && !data.config.tags && !data.config.frameworks) {
      isValid = false;
      SnackbarHelper.showTranslatableSnackBar(
        this.ngZone, this.snackBar, this.translationService, 'collector.message.error.missingSelectedTags'
      );
      return isValid;
    }
    if (data.config.tags) {
      if (data.config.tags.minRequiredTags && data.config.tags.tagUids.length < data.config.tags.minRequiredTags) {
        isValid = false;
        this.collectorFormContent.tagsCountError = true;
        SnackbarHelper.showTranslatableSnackBar(
          this.ngZone, this.snackBar, this.translationService, 'collector.message.error.minimumRequiredTagsCount'
        );
        return isValid;
      }
    }
    if (data.config.frameworks) {
      if (data.config.frameworks[0].minRequiredTags && data.config.frameworks.length < data.config.frameworks[0].minRequiredTags) {
        isValid = false;
        this.collectorFormContent.tagsCountError = true;
        SnackbarHelper.showTranslatableSnackBar(
          this.ngZone, this.snackBar, this.translationService, 'collector.message.error.minimumRequiredTagsCount'
        );
        return isValid;
      }
    }
    return isValid;
  }
}
